// components/LandingPage.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { HiOutlinePlus, HiOutlineShare, HiOutlineCollection, HiOutlineClipboardList } from "react-icons/hi";

function LandingPage() {
  return (
    <div className="">
      <Helmet>
        <title>Lendovu - Share More, Own Less | Organize and Share Your Items</title>
        <meta name="description" content="Simplify your life with Lendovu. Organize, track, and share your personal items effortlessly. Experience a clutter-free life while staying connected with friends and family." />
        <link rel="canonical" href="https://www.lendovu.com" />
      </Helmet>

      <section className="header-image text-center">
        <h1 className="text-5xl text-white font-bold mb-4">Share More, Own Less</h1>
        <p className="text-xl text-white mb-8">Simplify your life by organizing, tracking, and sharing your personal items effortlessly.</p>
        <Link to="/register" className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded-lg text-lg">
          Sign Up Now!
        </Link>
      </section>

      <section className="container mx-auto mb-16 p-8">
        <h2 className="text-3xl font-semibold text-center mb-8">
          Experience the joy of a <span className="text-orange-500">clutter-free</span> life while 
          staying connected with friends and family through <span className="text-orange-500">shared resources</span>.
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <FeatureCard 
            icon={<HiOutlineCollection className="w-12 h-12 text-orange-500" />}
            title="Create Multiple Collections"
            description="Organize your belongings into various categories like books, tools, gadgets, and more."
          />
          <FeatureCard 
            icon={<HiOutlineClipboardList className="w-12 h-12 text-orange-500" />}
            title="Detailed Item Information"
            description="Add comprehensive details to each item, including photos, descriptions, and usage instructions."
          />
          <FeatureCard 
            icon={<HiOutlineShare className="w-12 h-12 text-orange-500" />}
            title="Share Collections"
            description="Effortlessly share your collections with friends and family."
          />
          <FeatureCard 
            icon={<HiOutlinePlus className="w-12 h-12 text-orange-500" />}
            title="Track Borrowed and Lent Items (Coming Soon)"
            description="Keep an eye on what you've lent out and what you've borrowed."
          />
        </div>
      </section>

      <section className="bg-gray-100 rounded-lg p-8 mb-16">
        <div className='container mx-auto flex flex-col md:flex-row items-center'>
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img src="/img/tools-image.jpg" alt="Tools on wooden surface" className="rounded-lg shadow-lg" />
          </div>
          <div className="md:w-1/2 md:pl-8">
            <h2 className="text-3xl font-semibold mb-4">Why Lendovu?</h2>
            <p className="text-lg">
              Reimagine your lifestyle by reducing waste, saving money, and fostering community connections. 
              Lendovu transforms how you manage your possessions, ensuring that you and your loved ones 
              can make the most of what you already own.
            </p>
          </div>
        </div>
      </section>

      <section className="text-center p-8">
        <h2 className="text-3xl font-semibold mb-4">Ready to share more and own less?</h2>
        <p className="text-xl mb-8">Join Lendovu today and discover a new way to live connectedly and sustainably.</p>
        <Link to="/register" className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded-lg text-lg">
          Get Started!
        </Link>
      </section>
    </div>
  );
}

function FeatureCard({ icon, title, description }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-center mb-4">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p>{description}</p>
    </div>
  );
}

export default LandingPage;