// components/AppContent.js
import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import Discover from './Discover';
import Collection from './Collection';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Login from './Login';
import Register from './Register';
import Profile from './Profile';
import ProfileEdit from './ProfileEdit';
import PassReset from './PassReset';
import VerifyEmail from './VerifyEmail';
import ItemDetails from './ItemDetails';
import ItemList from './ItemList';
import AllItems from './AllItems';
import Search from './Search';
import LandingPage from './LandingPage';
import PricingPage from './PricingPage';
import UpgradePage from './UpgradePage';
import UpgradeSuccessPage from './UpgradeSuccessPage';
import DeleteAccount from './DeleteAccount';
import Notifications from './Notifications';
import About from './About';
import RefundPolicy from './RefundPolicy';
import AcceptInvitation from './AcceptInvitation';
import Unauthorized from './Unauthorized';

function AppContent({ cookieStatus }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getPageClass = () => {
    const path = location.pathname.split('/')[1] || 'home';
    return `page-${path}`;
  };

  return (
    <div className={`${getPageClass()}`}>
      <Header toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <main className="container mx-auto mt-4" role="main">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/discover" element={<ProtectedRoute><Discover /></ProtectedRoute>} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/all-items" element={<ProtectedRoute><AllItems /></ProtectedRoute>} />
          <Route path="/upgrade" element={<ProtectedRoute><UpgradePage /></ProtectedRoute>} />
          <Route path="/upgrade-success" element={<ProtectedRoute><UpgradeSuccessPage /></ProtectedRoute>} />
          <Route path="/items" element={<ProtectedRoute><ItemList /></ProtectedRoute>} />
          <Route path="/item/:itemId" element={<ProtectedRoute><ItemDetails /></ProtectedRoute>} />
          <Route path="/collection/:id" element={<ProtectedRoute requiredPermission="collection"><Collection /></ProtectedRoute>} />
          <Route path="/collection/:collectionId/item/:itemId" element={<ProtectedRoute requiredPermission="item"><ItemDetails /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/profile-edit" element={<ProtectedRoute><ProfileEdit /></ProtectedRoute>} />
          <Route path="/delete-account" element={<ProtectedRoute><DeleteAccount /></ProtectedRoute>} />
          <Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
          <Route path="/search" element={<ProtectedRoute><Search /></ProtectedRoute>} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/pass-reset" element={<PassReset />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<About />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/accept-invitation/:id" component={AcceptInvitation} />
        </Routes>
      </main>
      <Footer cookieStatus={cookieStatus} />
    </div>
  );
}

export default AppContent;