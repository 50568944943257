// hooks/useSharing.js

import { useCallback } from 'react';
import { 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc, 
  deleteDoc, 
  arrayUnion, 
  arrayRemove, 
  collection, 
  getDocs,
  firestore 
} from '../firebase';

export const useSharing = (user) => {
  const acceptCollectionInvitation = useCallback(async (invitationId) => {
    if (user) {
      try {
        const invitationRef = doc(firestore, `pendingInvitations/${invitationId}`);
        const invitationSnap = await getDoc(invitationRef);
        
        if (invitationSnap.exists()) {
          const invitationData = invitationSnap.data();
          
          // Add to user's sharedCollections
          await setDoc(doc(firestore, `users/${user.uid}/sharedCollections/${invitationData.collectionId}`), {
            ownerId: invitationData.ownerId
          });
  
          // Update the collection's sharedWith array
          const collectionRef = doc(firestore, `collections/${invitationData.collectionId}`);
          await updateDoc(collectionRef, {
            sharedWith: arrayUnion(user.uid)
          });
  
          // Delete the pending invitation
          await deleteDoc(invitationRef);
  
          console.log('Collection invitation accepted successfully');
        } else {
          throw new Error('Invitation not found');
        }
      } catch (error) {
        console.error('Error accepting collection invitation:', error);
        throw error;
      }
    } else {
      throw new Error('No user logged in');
    }
  }, [user]);

  const declineCollectionInvitation = useCallback(async (collectionId) => {
    if (user) {
      try {
        const collectionRef = doc(firestore, `collections/${collectionId}`);
        
        // Remove the user from the collection's pendingInvitations
        await updateDoc(collectionRef, {
          pendingInvitations: arrayRemove(user.uid)
        });

        console.log('Collection invitation declined successfully');
      } catch (error) {
        console.error('Error declining collection invitation:', error);
        throw error;
      }
    } else {
      throw new Error('No user logged in');
    }
  }, [user]);

  const getPendingInvitations = useCallback(async (collectionId) => {
    if (user) {
      try {
        const invitationsRef = collection(firestore, `collections/${collectionId}/pendingInvitations`);
        const snapshot = await getDocs(invitationsRef);
        return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error('Error fetching pending invitations:', error);
        throw error;
      }
    } else {
      throw new Error('No user logged in');
    }
  }, [user]);

  return {
    acceptCollectionInvitation,
    declineCollectionInvitation,
    getPendingInvitations,
  };
};