import React from 'react';

function TermsAndConditions() {

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Terms and Conditions</h1>
      <h2 className="text-xl font-semibold mt-4 mb-2">1. Acceptance of Terms</h2>
      <p>By using Lendovu, you agree to these Terms and Conditions. If you disagree with any part of these terms, please do not use our service.</p>

      <h2 className="text-xl font-semibold mt-4 mb-2">2. User Accounts</h2>
      <p>You must create an account to use Lendovu. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>

      <h2 className="text-xl font-semibold mt-4 mb-2">3. Content</h2>
      <p>Users are solely responsible for the content they add to Lendovu. This includes library names, item descriptions, and any other information. You agree not to post content that is illegal, offensive, or infringes on others' rights.</p>

      <h2 className="text-xl font-semibold mt-4 mb-2">4. Lending and Borrowing</h2>
      <p>Lendovu facilitates the sharing of information about personal items. However, we are not party to any lending or borrowing agreements between users. Any arrangements made between users are solely their responsibility.</p>

      <h2 className="text-xl font-semibold mt-4 mb-2">5. Liability</h2>
      <p>Lendovu is not responsible for any damage, loss, or theft of items listed on the app. All risks associated with lending or borrowing items are borne by the users involved in the transaction. We strongly recommend users take appropriate precautions when lending or borrowing items.</p>

      <h2 className="text-xl font-semibold mt-4 mb-2">6. Privacy</h2>
      <p>Your use of Lendovu is also governed by our Privacy Policy.</p>

      <h2 className="text-xl font-semibold mt-4 mb-2">7. Modifications</h2>
      <p>We reserve the right to modify these terms at any time. Continued use of the service after any such changes shall constitute your consent to such changes.</p>

      <h2 className="text-xl font-semibold mt-4 mb-2">8. Termination</h2>
      <p>We reserve the right to terminate or suspend your account at our sole discretion, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users, us, or third parties, or for any other reason.</p>

    </div>
  );
}

export default TermsAndConditions;