import React, { useState, useContext } from 'react';
import { CollectionContext } from '../contexts/CollectionContext';
import { AuthContext } from '../contexts/AuthContext';
import { firestore, storage } from '../firebase';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { showToast } from '../utils/toast';
import ImageUpload from './ImageUpload';
import { HiX } from "react-icons/hi";

const EditItemForm = ({ item, onEditSuccess, onDeleteSuccess, onClose }) => {
  const { user } = useContext(AuthContext);
  const { collections } = useContext(CollectionContext);

  const [editedName, setEditedName] = useState(item.name);
  const [editedDescription, setEditedDescription] = useState(item.description || '');
  const [editedImage, setEditedImage] = useState(null);
  const [editedImageUrl, setEditedImageUrl] = useState(item.imageUrl);
  const [selectedCollections, setSelectedCollections] = useState(item.collections || []);

  const handleImageSelected = (file) => {
    setEditedImage(file);
    if (file) {
      setEditedImageUrl(URL.createObjectURL(file));
    }
  };

  const handleRemoveImage = () => {
    setEditedImage(null);
    setEditedImageUrl(null);
  };

  const handleEdit = async () => {
    try {
      const itemRef = doc(firestore, `items/${item.id}`);
      let imageUrl = item.imageUrl;

      if (editedImage) {
        const storageRef = ref(storage, `itemImages/${user.uid}/${item.id}`);
        await uploadBytes(storageRef, editedImage);
        imageUrl = await getDownloadURL(storageRef);
      } else if (editedImageUrl === null && item.imageUrl) {
        const oldImageRef = ref(storage, item.imageUrl);
        await deleteObject(oldImageRef);
        imageUrl = null;
      }

      const updatedItem = {
        name: editedName,
        description: editedDescription,
        imageUrl: imageUrl,
        collections: selectedCollections,
        updatedAt: new Date()
      };

      await updateDoc(itemRef, updatedItem);

      onEditSuccess({ ...item, ...updatedItem });
    } catch (error) {
      console.error('Error updating item:', error);
      showToast.error(`Failed to update item: ${error.message}`);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      try {
        const itemRef = doc(firestore, 'items', item.id);
        await deleteDoc(itemRef);
        if (item.imageUrl) {
          const imageRef = ref(storage, item.imageUrl);
          await deleteObject(imageRef);
        }
        onDeleteSuccess(item.id);
        showToast.success('Item deleted successfully');
      } catch (error) {
        console.error('Error deleting item:', error);
        showToast.error(`Failed to delete item: ${error.message}`);
      }
    }
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Edit Item</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <HiX className="w-6 h-6" />
        </button>
      </div>

      {editedImageUrl ? (
        <div className="mb-4">
          <img src={editedImageUrl} alt="Preview" className="max-w-full h-auto mb-2" />
          <button onClick={handleRemoveImage} className="text-red-500">Remove Image</button>
        </div>
      ) : (
        <ImageUpload onImageSelected={handleImageSelected} />
      )}

      <input
        type="text"
        value={editedName}
        onChange={(e) => setEditedName(e.target.value)}
        className="w-full p-2 mb-2 border rounded"
        placeholder="Item Name"
      />

      <textarea
        value={editedDescription}
        onChange={(e) => setEditedDescription(e.target.value)}
        className="w-full p-2 mb-2 border rounded"
        placeholder="Item Description"
      />
      
      <div className="mb-4">
        <p className="font-bold mb-2">Assign to Collections:</p>
        {collections.map(collection => (
          <label key={collection.id} className="block mb-2">
            <input
              type="checkbox"
              value={collection.id}
              checked={selectedCollections.includes(collection.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedCollections([...selectedCollections, collection.id]);
                } else {
                  setSelectedCollections(selectedCollections.filter(id => id !== collection.id));
                }
              }}
              className="mr-2"
            />
            {collection.name}
          </label>
        ))}
      </div>

      <div className="flex justify-between">
        <button 
          onClick={handleEdit}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Save Changes
        </button>
        <button 
          onClick={handleDelete}
          className="bg-red-500 text-white px-4 py-2 rounded"
        >
          Delete Item
        </button>
      </div>
    </div>
  );
};

export default EditItemForm;