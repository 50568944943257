// utils/cookieConsent.js

import { getCookie, setCookie } from './cookies';

export const COOKIE_CONSENT_KEY = 'cookieConsent';

export function getCookieConsent() {
  return getCookie(COOKIE_CONSENT_KEY);
}

export function setCookieConsent(value) {
  setCookie(COOKIE_CONSENT_KEY, value, 365); // Store for a year
}

export function checkCookieConsent() {
  return getCookieConsent() === 'accepted';
}