// components/Footer.js
import { Link } from 'react-router-dom';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="mt-8 p-4">
      <div className="max-w-4xl mx-auto flex flex-col items-center">
        <div className="text-sm text-gray-600">
          &copy; {currentYear} Lendovu. All rights reserved. <Link to="/about" className="text-gray-500 hover:text-orange-500">About Us</Link> | <Link to="/refund-policy" className="text-gray-500 hover:text-orange-500">Refund Policy</Link> | <Link to="/terms" className="text-gray-500 hover:text-orange-500">Terms & Conditions</Link> | <Link to="/privacy" className="text-gray-500 hover:text-orange-500">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;