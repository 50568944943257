// components/BackButton.js
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiMiniArrowLeft } from "react-icons/hi2";

const BackButton = () => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <button
      onClick={handleClick}
      className="text-orange-500 mb-4 inline-flex items-center"
    >
      <HiMiniArrowLeft /> Back
    </button>
  );
};

export default BackButton;