// Register.js
import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { registerUser, signInWithGoogle } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
  
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }
  
    try {
      await registerUser(email, password);
      navigate('/verify-email');
    } catch (error) {
      console.error('Error registering user:', error);
      // Use a generic error message
      setError("An error occurred during registration. Please try again.");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      navigate('/discover');
    } catch (error) {
      console.error('Google sign-in error:', error);
      setError(error.message);
    }
  };

  return (
    <div className="max-w-md mx-auto p-8">
      <h2 className="text-2xl font-bold mb-4">Register</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block mb-2">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="select mb-4">
          <input type="checkbox" id="agreeTerms" required />
          <label htmlFor="agreeTerms" className="ml-2 terms-label">
            I agree to the <Link to="/terms" className="text-orange-500 hover:underline">Terms & Conditions</Link> and <Link to="/privacy" className="text-orange-500 hover:underline">Privacy Policy</Link>
          </label>
        </div>
        <button type="submit" className="w-full p-2 bg-orange-500 text-white rounded">
          Register
        </button>
      </form>
      <button 
        onClick={handleGoogleSignIn}
        className="w-full p-2 bg-white border border-gray-300 rounded flex items-center justify-center mt-4"
      >
        <FcGoogle className="mr-2" /> Sign up with Google
      </button>
      <div className="mt-4">
        <Link to="/login" className="text-orange-500">Already have an account? Login</Link>
      </div>
    </div>
  );
}

export default Register;