// components/About.js
import React from 'react';
import { HiMail } from "react-icons/hi";

function About() {

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">About Lendovu</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
        <p className="mb-4">
          Lendovu was born out of a simple yet common frustration: buying a tool and item that a friend or family member would have gladly let us borrow.
        </p>
        <p className="mb-4">
          Inspired by the spirit of sharing and community, we set out to create a solution that would make managing personal inventories and sharing items as easy as a few taps on a screen.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">The Meaning Behind Lendovu</h2>
        <p className="mb-4">
          The name "Lendovu" is a playful blend of "lend" and "Ndlovu" (Zulu for "Elephant"). It embodies our mission: to facilitate lending between you and your community. We believe in the power of sharing to build stronger connections and more sustainable lifestyles.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
        <p className="mb-4">
          We'd love to hear from you! Whether you have questions, feedback, or just want to say hello, feel free to reach out to us at:
        </p>
        <a href="mailto:support@lendovu.com" className="text-orange-500 hover:underline flex items-center">
          <HiMail className="mr-2" /> support@lendovu.com
        </a>
      </section>

      <section>
        <p className="text-sm text-gray-600">
          Thank you for being a part of the Lendovu community. Together, we're reimagining how we manage and share our possessions.
        </p>
      </section>
    </div>
  );
}

export default About;