// components/Collection.js
import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { CollectionContext } from '../contexts/CollectionContext';
import { AuthContext } from '../contexts/AuthContext';
import BackButton from './BackButton';
import Modal from './Modal';
import { showToast } from '../utils/toast';
import ShareCollectionModal from './ShareCollectionModal';
import { HiViewGrid, HiViewList, HiX, HiPencil } from "react-icons/hi";
import { firestore, doc, getDoc, collection as firestoreCollection, query, where, getDocs } from '../firebase';
import { storage } from '../firebase';
import { ref, getDownloadURL } from 'firebase/storage';

const Collection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { 
    editCollection, 
    deleteCollection,
    shareCollection,
    removeSharedAccess,
    getCollectionDetails,
  } = useContext(CollectionContext);
  const itemsRef = firestoreCollection(firestore, 'items');
  const [collection, setCollection] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [sharedUsers, setSharedUsers] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchCollectionAndItems = async () => {
      try {
        setLoading(true);
        const collectionDetails = await getCollectionDetails(id);
        
        if (collectionDetails) {
          setCollection(collectionDetails);
          setIsOwner(collectionDetails.ownerId === user.uid);
          setEditedName(collectionDetails.name);
    
          // Fetch items for this collection
          const itemsRef = firestoreCollection(firestore, 'items');
          const q = query(itemsRef, where('collections', 'array-contains', id));
          const itemsSnap = await getDocs(q);
          const itemsData = itemsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setItems(itemsData);
    
          // Fetch image URLs
          const urls = {};
          for (const item of itemsData) {
            if (item.imageUrl) {
              try {
                const imageRef = ref(storage, item.imageUrl);
                const url = await getDownloadURL(imageRef);
                urls[item.id] = url;
              } catch (error) {
                console.error(`Error fetching image for item ${item.id}:`, error);
                // Don't set a URL, let the img tag handle the error
              }
            }
          }
          setImageUrls(urls);
    
          // Fetch shared users
          if (collectionDetails.sharedWith) {
            const sharedUsersData = await Promise.all(
              collectionDetails.sharedWith.map(async (userId) => {
                const userRef = doc(firestore, 'users', userId);
                const userSnap = await getDoc(userRef);
                return { id: userId, ...userSnap.data() };
              })
            );
            setSharedUsers(sharedUsersData);
          }
        } else {
          setError('Collection not found');
          navigate('/discover');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching collection and items:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCollectionAndItems();
  }, [id, user.uid, navigate, getCollectionDetails]);

  const handleEdit = async () => {
    try {
      await editCollection(id, editedName);
      setCollection({ ...collection, name: editedName });
      setIsEditModalOpen(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this collection? This action cannot be undone.')) {
      try {
        await deleteCollection(id);
        showToast.success('Collection deleted successfully');
        navigate('/discover');
      } catch (error) {
        console.error('Error deleting collection:', error);
        showToast.error('Failed to delete collection. Please try again.');
        setError(error.message);
      }
    }
  };

  const handleShareCollection = async (email) => {
    try {
      await shareCollection(id, email);
      // Refresh shared users after sharing
      const collectionRef = doc(firestore, 'collections', id);
      const collectionSnap = await getDoc(collectionRef);
      const collectionData = collectionSnap.data();
      if (collectionData.sharedWith) {
        const sharedUsersData = await Promise.all(
          collectionData.sharedWith.map(async (userId) => {
            const userRef = doc(firestore, 'users', userId);
            const userSnap = await getDoc(userRef);
            return { id: userId, ...userSnap.data() };
          })
        );
        setSharedUsers(sharedUsersData);
      }
    } catch (error) {
      console.error('Error sharing collection:', error);
      throw error;
    }
  };
  
  const handleRemoveAccess = async (userIdToRemove) => {
    try {
      await removeSharedAccess(id, userIdToRemove);
      setSharedUsers(sharedUsers.filter(user => user.id !== userIdToRemove));
    } catch (error) {
      console.error('Error removing shared access:', error);
      setError(error.message);
    }
  };

  if (loading) return <div className="text-center mt-8">Loading...</div>;
  if (error) return <div className="text-center mt-8 text-red-500">Error: {error}</div>;
  if (!collection) return <div className="text-center mt-8">Collection not found</div>;

  return (
    <div className="max-w-6xl mx-auto mt-8 p-4">
      <BackButton />
      <div className='flex justify-between items-center mb-4'>
        <h2 className="text-2xl font-bold">{collection.name}</h2>
        {isOwner && (
          <div className='flex'>
            <button onClick={() => setIsEditModalOpen(true)} className="p-2 mr-2 flex items-center">
              <HiPencil className='mr-2' /> Edit
            </button>
            <button 
              onClick={() => setIsShareModalOpen(true)}
              className="bg-orange-500 text-white p-2 rounded flex items-center"
            >
              Share
              {sharedUsers.length > 0 && (
                <span className="ml-2 bg-orange-700 px-2 py-1 rounded-full text-xs">
                  {sharedUsers.length}
                </span>
              )}
            </button>
          </div>
        )}
      </div>
      <div className="flex space-x-2 mb-4">
        <button 
          onClick={() => setViewMode('grid')} 
          className={`p-2 rounded ${viewMode === 'grid' ? 'bg-orange-500 text-white' : 'bg-gray-200'}`}
        >
          <HiViewGrid />
        </button>
        <button 
          onClick={() => setViewMode('list')} 
          className={`p-2 rounded ${viewMode === 'list' ? 'bg-orange-500 text-white' : 'bg-gray-200'}`}
        >
          <HiViewList />
        </button>
      </div>

      {items.length === 0 ? (
        <p>No items in this collection</p>
      ) : (
        <ul className={`${viewMode === 'grid' ? 'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4' : 'space-y-4'}`}>
          {items.map(item => (
            <li key={item.id} className={`${viewMode === 'grid' ? 'border rounded-lg p-2' : 'border-b pb-4'}`}>
              <Link to={`/item/${item.id}`} className={`block ${viewMode === 'list' ? 'flex items-center' : ''}`}>
                {item.imageUrl ? (
                  <img 
                    src={imageUrls[item.id] || item.imageUrl} 
                    alt={item.name} 
                    className={`${viewMode === 'grid' ? 'w-full h-32 object-cover mb-2 rounded' : 'w-20 h-20 object-cover mr-4 rounded'}`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/img/placeholder-image.jpg'; // Ensure this path is correct
                    }}
                  />
                ) : (
                  <div className={`bg-gray-200 flex items-center justify-center ${viewMode === 'grid' ? 'w-full h-32 mb-2 rounded' : 'w-20 h-20 mr-4 rounded'}`}>
                    No Image
                  </div>
                )}
                <div className="flex-grow">
                  <h3 className="font-semibold text-sm sm:text-base truncate">{item.name}</h3>
                  <p className="text-xs sm:text-sm text-gray-600 truncate">{item.description}</p>
                  {item.ownerId !== user.uid && (
                    <span className="text-xs bg-orange-100 text-orange-800 px-2 py-1 rounded mt-1 inline-block">
                      Shared
                    </span>
                  )}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}

      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Edit Collection</h2>
            <button onClick={() => setIsEditModalOpen(false)} className="text-gray-500 hover:text-gray-700">
              <HiX className="w-6 h-6" />
            </button>
          </div>
          <input
            type="text"
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            className="w-full p-2 mb-4 border rounded"
            placeholder="Collection Name"
          />
          <div className="flex justify-between">
            <button onClick={handleEdit} className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">
              Save
            </button>
            <button onClick={handleDelete} className="bg-white text-red-700 border border-red-500 hover:bg-red-100 font-bold py-2 px-4 rounded">
              Delete Collection
            </button>
          </div>
        </div>
      </Modal>
      
      <ShareCollectionModal 
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        collectionId={id}
        sharedUsers={sharedUsers}
        onShareCollection={handleShareCollection}
        onRemoveAccess={handleRemoveAccess}
      />
    </div>
  );
};

export default Collection;