import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { CollectionContext } from '../contexts/CollectionContext';
import { AuthContext } from '../contexts/AuthContext';
import { firestore, storage } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { showToast } from '../utils/toast';
import BackButton from './BackButton';
import { HiPencil, HiX } from "react-icons/hi";
import Modal from './Modal';
import EditItemForm from './EditItemForm';

const ItemDetails = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { collections, sharedCollections } = useContext(CollectionContext);
  
  const [item, setItem] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        setLoading(true);
        const itemRef = doc(firestore, 'items', itemId);
        const itemSnap = await getDoc(itemRef);
        
        if (itemSnap.exists()) {
          const itemData = { id: itemSnap.id, ...itemSnap.data() };
          setItem(itemData);
          setIsOwner(itemData.ownerId === user.uid);

          if (itemData.imageUrl) {
            try {
              const url = await getDownloadURL(ref(storage, itemData.imageUrl));
              setImageUrl(url);
            } catch (imageError) {
              console.error('Error loading image:', imageError);
              setError('Failed to load image');
            }
          }
        } else {
          setError('Item not found');
        }
      } catch (err) {
        console.error('Error fetching item details:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchItemDetails();
  }, [itemId, user.uid]);

  const handleEditSuccess = (updatedItem) => {
    setItem(updatedItem);
    setIsEditing(false);
    showToast.success('Item updated successfully');
  };

  const handleDeleteSuccess = () => {
    showToast.success('Item deleted successfully');
    navigate(-1);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!item) return <div>Item not found</div>;

  return (
    <div className="max-w-4xl mx-auto p-4">
      <BackButton />
      <div className="mb-4">
        {imageUrl ? (
          <img 
            src={imageUrl} 
            alt={item?.name} 
            className="w-full h-64 object-cover mb-2 rounded cursor-pointer"
            onClick={() => setIsImageModalOpen(true)}
            onError={() => {
              setImageUrl(null);
              setError('Failed to load image');
            }}
          />
        ) : (
          item?.imageUrl && <div className="w-full h-64 bg-gray-200 flex items-center justify-center mb-2 rounded">Image not available</div>
        )}
        <h1 className="text-2xl font-bold">{item?.name}</h1>
        <p>{item?.description}</p>
      </div>

      {isOwner && (
        <button 
          onClick={() => setIsEditing(true)} 
          className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
        >
          <HiPencil className="inline mr-2" /> Edit
        </button>
      )}

      <Modal isOpen={isEditing} onClose={() => setIsEditing(false)}>
        <EditItemForm 
          item={item} 
          onEditSuccess={handleEditSuccess} 
          onDeleteSuccess={handleDeleteSuccess}
          onClose={() => setIsEditing(false)} 
        />
      </Modal>

      <Modal isOpen={isImageModalOpen} onClose={() => setIsImageModalOpen(false)}>
        <div className="relative">
          <button
            onClick={() => setIsImageModalOpen(false)}
            className="absolute top-2 right-2 text-white bg-black bg-opacity-50 rounded-full p-2"
          >
            <HiX className="w-6 h-6" />
          </button>
          <img 
            src={imageUrl} 
            alt={item?.name} 
            className="max-w-full max-h-[90vh] object-contain"
          />
        </div>
      </Modal>

      <h2 className="text-xl font-semibold mt-4 mb-2">Collections</h2>
      <ul className="list-disc pl-5">
        {item.collections && item.collections.map(collectionId => {
          const collection = [...collections, ...sharedCollections].find(c => c.id === collectionId);
          return collection ? (
            <li key={collectionId}>
              <Link to={`/collection/${collectionId}`} className="text-blue-500 hover:underline">
                {collection.name}
              </Link>
            </li>
          ) : null;
        })}
      </ul>
    </div>
  );
};

export default ItemDetails;