// components/RefundPolicy.js
import React from 'react';
import { Link } from 'react-router-dom';

function RefundPolicy() {

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Refund Policy</h1>
      
      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">1. Free Plan</h2>
        <p>As our Free Plan does not involve any monetary charges, no refunds are applicable.</p>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">2. Pro Plan Subscriptions</h2>
        <p>We offer a 14-day money-back guarantee for our Pro Plan subscriptions. If you're not satisfied with our service, you can request a full refund within 14 days of your initial subscription purchase.</p>
        <p className="mt-2">To request a refund:</p>
        <ul className="list-disc ml-6 mt-2">
          <li>Contact our support team at <a href="mailto:support@lendovu.com" className="text-orange-500 hover:underline">support@lendovu.com</a></li>
          <li>Provide your account email and the reason for your refund request</li>
          <li>Our team will process your request within 5 business days</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">3. Refund Eligibility</h2>
        <p>Refunds are only available for Pro Plan subscriptions within the first 14 days of the initial purchase. Refunds are not available for:</p>
        <ul className="list-disc ml-6 mt-2">
          <li>Subscription renewals after the initial 14-day period</li>
          <li>Partial refunds for unused time on active subscriptions</li>
          <li>Accounts that have violated our <Link to="/terms" className="text-orange-500 hover:underline">Terms of Service</Link></li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">4. Refund Process</h2>
        <p>Once your refund is approved:</p>
        <ul className="list-disc ml-6 mt-2">
          <li>The refund will be processed to the original payment method used for the purchase</li>
          <li>It may take 5-10 business days for the refund to appear on your statement, depending on your payment provider</li>
          <li>Your Pro Plan features will be reverted to the Free Plan upon processing of the refund</li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-2xl font-semibold mb-3">5. Changes to This Policy</h2>
        <p>We reserve the right to modify this refund policy at any time. Changes will be effective immediately upon posting to our website. Your continued use of Lendovu after any changes to this policy constitutes your acceptance of such changes.</p>
      </section>

      <p className="mt-8">
        If you have any questions about our refund policy, please contact us at{' '}
        <a href="mailto:support@lendovu.com" className="text-orange-500 hover:underline">support@lendovu.com</a>.
      </p>
    </div>
  );
}

export default RefundPolicy;