import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { HiBadgeCheck, HiBan } from "react-icons/hi";

function Profile() {
  const { user, refreshUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      refreshUser();
    }
  }, [user, navigate, refreshUser]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-profile-details container max-w-md mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Profile</h2>
      <div className="mb-4">
        <p className='font-bold'>Email</p> {user.email}
        {user.emailVerified ? (
          <p className="text-green-500 flex items-center"><HiBadgeCheck /> Email verified</p>
        ) : (
          <p className="text-red-500 flex items-center"><HiBan /> Email not verified. <Link to="/verify-email" className="text-orange-500">Verify now</Link></p>
        )}
      </div>
      <div className="mb-4">
        <p className='font-bold'>Display Name</p> {user.displayName || 'Not set'}
      </div>
      <div className="mb-4">
        <p className='font-bold'>Phone Number</p> {user.phoneNumber || 'Not set'}
      </div>
      <div className="mb-4">
        <p className='font-bold'>Preferred Contact Method</p>{' '}
        {user.preferredContact === 'email' ? 'Email' :
        user.preferredContact === 'phone-call' ? 'Phone - Call' :
        user.preferredContact === 'phone-text' ? 'Phone - Text' :
        'Not set'}
      </div>
      <div className="mt-4">
        <button 
          onClick={() => navigate('/profile-edit')}
          className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
        >
          Edit Profile
        </button>
        <div className="mt-4">
          <Link 
            to="/upgrade"
            className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
          >
            Upgrade to Pro
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Profile;