import React, { useEffect, useRef, useState, useContext } from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { CollectionContext } from '../contexts/CollectionContext';
import Modal from './Modal';
import AddCollection from './AddCollection';
import { auth, firestore, doc, getDoc } from '../firebase';
import { showToast } from '../utils/toast';
import { HiPlus, HiXMark, HiChevronRight, HiSquares2X2, HiInformationCircle, HiViewColumns } from "react-icons/hi2";
import { MAX_COLLECTIONS } from '../hooks/useCollections';
import { MAX_ITEMS } from '../hooks/useItems';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { user } = useContext(AuthContext);
  const { collections, addCollection, userItemCount, sharedCollections } = useContext(CollectionContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sharedCollectionsWithOwners, setSharedCollectionsWithOwners] = useState([]);
  const sidebarRef = useRef(null);
  const closeButtonRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOwnerInfo = async () => {
      const sharedWithOwners = await Promise.all(
        sharedCollections.map(async (collection) => {
          if (collection.ownerId) {
            const ownerRef = doc(firestore, 'users', collection.ownerId);
            const ownerSnap = await getDoc(ownerRef);
            if (ownerSnap.exists()) {
              const ownerData = ownerSnap.data();
              return {
                ...collection,
                ownerDisplayName: ownerData.displayName || ownerData.email || 'Unknown',
              };
            }
          }
          return { ...collection, ownerDisplayName: 'Unknown' };
        })
      );
      setSharedCollectionsWithOwners(sharedWithOwners);
    };

    if (sharedCollections.length > 0) {
      fetchOwnerInfo();
    }
  }, [sharedCollections]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && isOpen) {
        toggleSidebar();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
      closeButtonRef.current?.focus();
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, toggleSidebar]);

  const handleFocusTrap = (event) => {
    const focusableElements = sidebarRef.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        if (document.activeElement === firstElement) {
          lastElement.focus();
          event.preventDefault();
        }
      } else {
        if (document.activeElement === lastElement) {
          firstElement.focus();
          event.preventDefault();
        }
      }
    }
  };

  const handleNavigation = (to) => {
    navigate(to);
    toggleSidebar();
  };

  const handleAddCollectionClick = () => {
    if (user && !user.emailVerified) {
      showToast.warning(
        <div>
          Please <Link to="/verify-email" className="text-blue-500 underline">verify your email</Link> before adding collections.
        </div>
      );
    } else if (!user.isPremium && collections.length >= MAX_COLLECTIONS) {
      showToast.warning(
        <div>
          You can only create a maximum of {MAX_COLLECTIONS} collections on the free plan.{' '}
          <Link to="/upgrade" className="text-blue-500 underline" onClick={toggleSidebar}>
            Upgrade to Pro
          </Link>
        </div>
      );
    } else {
      setIsModalOpen(true);
    }
  };

  const handleAddCollection = async (name) => {
    try {
      await addCollection(name);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error adding collection:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
      toggleSidebar();
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const isCollectionActive = (collectionId) => {
    return location.pathname === `/collection/${collectionId}`;
  };

  return (
    <>
      <div 
        className={`fixed inset-0 bg-black bg-opacity-50 z-20 ${isOpen ? 'block' : 'hidden'}`}
        onClick={toggleSidebar}
        aria-hidden="true"
      ></div>
      <aside 
        ref={sidebarRef}
        className={`sidebar-menu fixed top-0 left-0 h-full bg-white shadow-lg z-30 transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } ${isOpen ? 'block' : 'hidden'}`}
        role="navigation"
        aria-label="Main menu"
        aria-hidden={!isOpen}
        onKeyDown={handleFocusTrap}
      >
        <div className='close-button-row p-4'>
          <button 
            ref={closeButtonRef}
            onClick={toggleSidebar} 
            className="close-menu-icon text-2xl"
            aria-label="Close menu"
          >
            <HiXMark />
          </button>
        </div>
        <div className="sidebar-content">
          <div className='sidebar-scrollable-content'>
            <nav className="">
              <ul className="pb-4">
                {user && (
                  <>
                    <li>
                      <div className="my-collections-header flex items-center">
                        <h3 className="font-bold">My Collections</h3>
                        <button 
                          onClick={handleAddCollectionClick}
                          className=""
                          aria-label="Add new collection"
                        >
                          <HiPlus className='text-2xl ml-4' />
                        </button>
                      </div>
                      <ul className="">
                        <div>
                          <NavLink 
                            to="/all-items" 
                            onClick={() => handleNavigation('/all-items')}
                            className={({ isActive }) => 
                              `flex items-center p-2 mt-2 hover:text-orange-500 ${isActive ? 'bg-gray-100 rounded' : ''}`
                            }
                          >
                            <HiViewColumns className='mr-2' /> All Items
                          </NavLink>
                        </div>
                        {collections.map(collection => (
                          <li key={collection.id}>
                            <NavLink 
                              to={`/collection/${collection.id}`} 
                              onClick={() => handleNavigation(`/collection/${collection.id}`)}
                              className={({ isActive }) => 
                                `flex items-center p-2 hover:text-orange-500 ${isActive || isCollectionActive(collection.id) ? 'bg-gray-100 rounded' : ''}`
                              }
                            >
                              <HiSquares2X2 className='mr-2' /> {collection.name}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>

                    {/* Shared Collections Section */}
                    {sharedCollectionsWithOwners.length > 0 && (
                      <li className="mt-4">
                        <h3 className="font-bold mb-2">Shared Collections</h3>
                        {sharedCollectionsWithOwners.map(collection => (
                          <div key={collection.id} className="mb-2">
                            <p className="text-sm text-gray-600">
                              {collection.ownerDisplayName}
                            </p>
                            <NavLink 
                              to={`/collection/${collection.id}`} 
                              onClick={() => handleNavigation(`/collection/${collection.id}`)}
                              className={({ isActive }) => 
                                `flex items-center p-2 hover:text-orange-500 ${isActive || isCollectionActive(collection.id) ? 'bg-gray-100 rounded' : ''}`
                              }
                            >
                              <HiSquares2X2 className='mr-2' /> {collection.name}
                            </NavLink>
                          </div>
                        ))}
                      </li>
                    )}

                    {user && !user.isPremium && (
                      <div className="px-2 py-2 bg-gray-200 border border-gray-200 rounded mt-4">
                        <h3 className="text-sm font-semibold mb-2 flex items-center">
                          <HiInformationCircle className="mr-1" /> Free Plan Usage
                        </h3>
                        <p className="text-sm mb-1">
                          Collections: {collections.length}/{MAX_COLLECTIONS}
                        </p>
                        <p className="text-sm mb-1">
                          Items: {userItemCount}/{MAX_ITEMS}
                        </p>
                        <p className="text-sm mb-2">
                          Storage: {user.storageUsage ? user.storageUsage.toFixed(2) : '0'}/20 MB
                        </p>
                        <Link 
                          to="/upgrade"
                          className="block text-center py-2 px-4 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors"
                          onClick={toggleSidebar}
                        >
                          Upgrade to Pro
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </ul>
            </nav>
          </div>
        </div>
        <div className='sidebar-footer bg-gray-100 border-t'>
          {user ? (
            <>
              <div className='profile-name'>
                <NavLink 
                  to="/profile" 
                  onClick={() => handleNavigation('/profile')} 
                  className={({ isActive }) => 
                    `flex justify-between items-center p-2 ${isActive ? 'bg-gray-200 rounded' : ''}`
                  }
                >
                  {user.displayName || user.email} <HiChevronRight />
                </NavLink>
              </div>
              <div>
                <button 
                  onClick={handleLogout}
                  className="w-full text-left p-2 hover:bg-gray-200"
                >
                  Logout
                </button>
              </div>
            </>
          ) : (
            <div>
              <Link 
                to="/login" 
                onClick={toggleSidebar}
                className="w-full text-left p-2 hover:bg-gray-200 block"
              >
                Login
              </Link>
              <Link 
                to="/register" 
                onClick={toggleSidebar}
                className="w-full text-left p-2 hover:bg-gray-200 block"
              >
                Register
              </Link>
            </div>
          )}
        </div>
      </aside>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddCollection onAddCollection={handleAddCollection} onClose={() => setIsModalOpen(false)} />
      </Modal>
    </>
  );
};

export default Sidebar;