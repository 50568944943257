import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { CollectionContext } from '../contexts/CollectionContext';
import { firestore, doc, getDoc } from '../firebase';

function ProtectedRoute({ children, requiredPermission }) {
  const { user, loading } = useContext(AuthContext);
  const { getAllItems, collections, sharedCollections } = useContext(CollectionContext);
  const [hasPermission, setHasPermission] = useState(false);
  const [checkingPermission, setCheckingPermission] = useState(true);
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    async function checkPermission() {
      if (!user) return;

      if (requiredPermission === 'collection') {
        const collectionRef = doc(firestore, 'collections', params.id);
        const collectionSnap = await getDoc(collectionRef);
        if (collectionSnap.exists()) {
          const collectionData = collectionSnap.data();
          setHasPermission(
            collectionData.ownerId === user.uid || 
            (collectionData.sharedWith && collectionData.sharedWith.includes(user.uid)) ||
            sharedCollections.some(sc => sc.id === params.id)
          );
        }
      } else if (requiredPermission === 'item') {
        const { allItems } = await getAllItems();
        const item = allItems.find(item => item.id === params.itemId);
        if (item) {
          setHasPermission(
            item.ownerId === user.uid || 
            (item.collections && item.collections.some(collectionId => 
              collections.some(c => c.id === collectionId) || 
              sharedCollections.some(sc => sc.id === collectionId)
            ))
          );
        }
      } else {
        setHasPermission(true);
      }

      setCheckingPermission(false);
    }

    checkPermission();
  }, [user, requiredPermission, params, getAllItems, collections, sharedCollections]);

  if (loading || checkingPermission) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!hasPermission) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
}

export default ProtectedRoute;