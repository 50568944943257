import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { auth, firestore, doc, getDoc, updateDoc, deleteDoc } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';

const AcceptInvitation = () => {
  const { id } = useParams();
  const [invitation, setInvitation] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInvitation = async () => {
      const invitationRef = doc(firestore, 'pendingInvitations', id);
      const invitationSnapshot = await getDoc(invitationRef);
      if (invitationSnapshot.exists()) {
        setInvitation(invitationSnapshot.data());
        setEmail(invitationSnapshot.data().recipientEmail);
      } else {
        setError('Invitation not found');
      }
    };
    fetchInvitation();
  }, [id]);

  const handleAccept = async (e) => {
    e.preventDefault();
    try {
      let user;
      if (invitation.isNewUser) {
        user = await createUserWithEmailAndPassword(auth, email, password);
      } else {
        user = await signInWithEmailAndPassword(auth, email, password);
      }

      // Add collection to user's shared collections
      await updateDoc(doc(firestore, `users/${user.uid}/sharedCollections`, invitation.collectionId), {
        ownerId: invitation.ownerUid
      });

      // Add user to collection's shared users
      await updateDoc(doc(firestore, `users/${invitation.ownerUid}/collections/${invitation.collectionId}/sharedWith`, user.uid), true);

      // Remove the invitation
      await deleteDoc(doc(firestore, 'pendingInvitations', id));

      // Redirect to the shared collection
      // You might want to use React Router's navigate here
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h1>Accept Invitation</h1>
      {error && <p>{error}</p>}
      {invitation && (
        <form onSubmit={handleAccept}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit">Accept Invitation</button>
        </form>
      )}
    </div>
  );
};

export default AcceptInvitation;