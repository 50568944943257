import React, { useState, useContext } from 'react';
import { CollectionContext } from '../contexts/CollectionContext';
import { AuthContext } from '../contexts/AuthContext';
import { showToast } from '../utils/toast';
import { HiX } from "react-icons/hi";
import { MAX_COLLECTIONS } from '../hooks/useCollections';
import { Link } from 'react-router-dom';

function AddCollection({ onClose }) {
  const [name, setName] = useState('');
  const [error, setError] = useState(null);
  const { addCollection, collections } = useContext(CollectionContext);
  const { user } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!user.emailVerified) {
      showToast.warning('Please verify your email before adding collections.');
      return;
    }

    if (!user.isPremium && collections.length >= MAX_COLLECTIONS) {
      showToast.warning(
        <div>
          You can only create a maximum of {MAX_COLLECTIONS} collections on the free plan.{' '}
          <Link to="/upgrade" className="text-blue-500 underline">
            Upgrade to Pro
          </Link>
        </div>
      );
      return;
    }

    try {
      await addCollection(name);
      setName('');
      onClose();
      showToast.success('Collection added successfully');
    } catch (error) {
      console.error('Error adding collection:', error);
      setError(error.message);
      showToast.error('Failed to add collection. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl">Add New Collection</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <HiX className="w-6 h-6" />
        </button>
      </div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Collection name"
        className="w-full p-2 mb-4 border rounded"
        required
      />
      
      <button 
        type="submit" 
        className="w-full p-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors"
      >
        Add Collection
      </button>
    </form>
  );
}

export default AddCollection;