import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { CollectionContext } from '../contexts/CollectionContext';
import { firestore, collection, query, orderBy, onSnapshot, doc, deleteDoc } from '../firebase';
import { HiThumbUp, HiEye } from "react-icons/hi";
import { showToast } from '../utils/toast';

const Notifications = () => {
  const { user } = useContext(AuthContext);
  const { getCollectionDetails } = useContext(CollectionContext);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (user) {
      const notificationsRef = collection(firestore, `users/${user.uid}/notifications`);
      const q = query(notificationsRef, orderBy('timestamp', 'desc'));
      
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const notificationsArray = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setNotifications(notificationsArray);
      });

      return () => unsubscribe();
    }
  }, [user]);

  const handleDismiss = async (notificationId) => {
    try {
      await deleteDoc(doc(firestore, `users/${user.uid}/notifications/${notificationId}`));
      showToast.success('Notification dismissed');
    } catch (error) {
      console.error('Error dismissing notification:', error);
      showToast.error('Failed to dismiss notification');
    }
  };

  const renderNotification = (notification) => {
    if (notification.type === 'collection_invitation') {
      return (
        <li key={notification.id} className="mb-4 p-4 border rounded flex justify-between items-center">
          <p>
            <strong>{notification.sharedByEmail}</strong> shared the collection <strong>"{notification.collectionName}"</strong> with you
          </p>
          <div>
            <Link
              to={`/collection/${notification.collectionId}`}
              className="bg-blue-500 text-white px-4 py-2 rounded mr-2 inline-flex items-center"
              onClick={() => handleDismiss(notification.id)}
            >
              <HiEye className="mr-1" /> View Collection
            </Link>
            <button
              onClick={() => handleDismiss(notification.id)}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded"
            >
              Dismiss
            </button>
          </div>
        </li>
      );
    }
    return null;
  };

  const renderContent = () => {
    const validNotifications = notifications.map(renderNotification).filter(Boolean);
    
    if (validNotifications.length === 0) {
      return (
        <div className="text-center py-8">
          <HiThumbUp className="mx-auto text-5xl text-green-500 mb-4" />
          <p className="text-xl font-semibold text-gray-700">You're all caught up!</p>
          <p className="text-gray-500">No new notifications at the moment.</p>
        </div>
      );
    }

    return <ul>{validNotifications}</ul>;
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Notifications</h2>
      {renderContent()}
    </div>
  );
};

export default Notifications;