// components/AllItems.js
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CollectionContext } from '../contexts/CollectionContext';
import BackButton from './BackButton';
import Modal from './Modal';
import { HiExclamationCircle, HiX, HiChevronDown, HiChevronUp } from "react-icons/hi";
import { showToast } from '../utils/toast';
import { storage } from '../firebase';
import { ref, getDownloadURL } from 'firebase/storage';

const AllItems = () => {
  const { getAllItems, collections, editItem } = useContext(CollectionContext);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [openAccordions, setOpenAccordions] = useState({});
  const [imageUrls, setImageUrls] = useState({});

  const fetchItems = useCallback(async () => {
    try {
      setLoading(true);
      const { userCreatedItems } = await getAllItems();
      setItems(userCreatedItems);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching items:', err);
      setError(err.message);
      setLoading(false);
    }
  }, [getAllItems]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      const urls = {};
      for (const item of items) {
        if (item.imageUrl) {
          try {
            const imageRef = ref(storage, item.imageUrl);
            const url = await getDownloadURL(imageRef);
            urls[item.id] = url;
          } catch (error) {
            console.error(`Error fetching image for item ${item.id}:`, error);
          }
        }
      }
      setImageUrls(urls);
    };

    if (items.length > 0) {
      fetchImageUrls();
    }
  }, [items]);

  const groupedItems = useMemo(() => {
    const grouped = { Unassigned: [] };
    items.forEach(item => {
      if (!item.collections || item.collections.length === 0) {
        grouped.Unassigned.push(item);
      } else {
        let assignedToExistingCollection = false;
        item.collections.forEach(collectionId => {
          const collection = collections.find(c => c.id === collectionId);
          if (collection) {
            if (!grouped[collection.name]) {
              grouped[collection.name] = [];
            }
            grouped[collection.name].push(item);
            assignedToExistingCollection = true;
          }
        });
        if (!assignedToExistingCollection) {
          grouped.Unassigned.push(item);
        }
      }
    });
    return grouped;
  }, [items, collections]);

  useEffect(() => {
    const initialOpenState = Object.keys(groupedItems).reduce((acc, collectionName) => {
      acc[collectionName] = true;
      return acc;
    }, {});
    setOpenAccordions(initialOpenState);
  }, [groupedItems]);

  const toggleAccordion = (collectionName) => {
    setOpenAccordions(prev => ({
      ...prev,
      [collectionName]: !prev[collectionName]
    }));
  };

  const handleAssignCollection = (item) => {
    setSelectedItem(item);
    setSelectedCollections(item.collections || []);
    setIsModalOpen(true);
  };

  const handleAssign = async () => {
    try {
      // Ensure selectedCollections is always an array
      const collectionsToAssign = selectedCollections.length > 0 ? selectedCollections : [];
      await editItem(selectedItem.id, selectedItem, collectionsToAssign);
      showToast.success('Item assigned to collections successfully');
      setIsModalOpen(false);
      fetchItems(); // Refresh the items list
    } catch (error) {
      console.error('Error assigning item to collections:', error);
      showToast.error('Failed to assign item to collections');
    }
  };

  const renderItem = (item, isUnassigned) => (
    <li key={item.id} className="border-b last:border-b-0 py-4 flex justify-between items-center">
      <div className="flex items-center">
        {imageUrls[item.id] ? (
          <img src={imageUrls[item.id]} alt={item.name} className="w-16 h-16 object-cover rounded mr-4" />
        ) : (
          <div className="w-16 h-16 bg-gray-200 flex items-center justify-center rounded mr-4">
            No Image
          </div>
        )}
        <div>
          <Link to={`/item/${item.id}`} className="text-lg font-semibold hover:underline">
            {item.name}
          </Link>
          <p className="text-sm text-gray-600">{item.description}</p>
        </div>
      </div>
      {isUnassigned && (
        <button
          onClick={() => handleAssignCollection(item)}
          className="px-4 py-2 rounded flex items-center bg-yellow-500 text-white"
        >
          <HiExclamationCircle className="mr-2" /> Assign
        </button>
      )}
    </li>
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="max-w-4xl mx-auto p-4">
      <BackButton />
      <h1 className="text-3xl font-bold mb-6">My Items</h1>
      {Object.keys(groupedItems).length === 0 ? (
        <p>You haven't created any items yet.</p>
      ) : (
        Object.entries(groupedItems).map(([collectionName, collectionItems]) => (
          <div key={collectionName} className="mb-4 border rounded-lg overflow-hidden">
            <button
              className="w-full p-4 text-left bg-gray-100 hover:bg-gray-200 flex justify-between items-center"
              onClick={() => toggleAccordion(collectionName)}
            >
              <h2 className="text-xl font-semibold">{collectionName}</h2>
              {openAccordions[collectionName] ? <HiChevronUp /> : <HiChevronDown />}
            </button>
            {openAccordions[collectionName] && (
              <ul className="p-4">
                {collectionItems.map(item => renderItem(item, collectionName === 'Unassigned'))}
              </ul>
            )}
          </div>
        ))
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {selectedItem && (
          <div className="p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Assign {selectedItem.name} to Collections</h2>
              <button onClick={() => setIsModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                <HiX className="w-6 h-6" />
              </button>
            </div>
            <div className="mb-4">
              {collections.map(collection => (
                <label key={collection.id} className="block mb-2">
                  <input
                    type="checkbox"
                    value={collection.id}
                    checked={selectedCollections.includes(collection.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedCollections([...selectedCollections, collection.id]);
                      } else {
                        setSelectedCollections(selectedCollections.filter(id => id !== collection.id));
                      }
                    }}
                    className="mr-2"
                  />
                  {collection.name}
                </label>
              ))}
            </div>
            <button
              onClick={handleAssign}
              className="w-full p-2 bg-orange-500 text-white rounded"
            >
              Save Changes
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AllItems;