import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

function ProfileEdit() {
  const { user, updateUserProfile } = useContext(AuthContext);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [preferredContact, setPreferredContact] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setDisplayName(user.displayName || '');
      setEmail(user.email || '');
      setPhoneNumber(user.phoneNumber || '');
      setPreferredContact(user.preferredContact || '');
    } else {
      navigate('/login');
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);
    try {
      const updates = {};
      if (displayName !== user.displayName) updates.displayName = displayName;
      if (email !== user.email) updates.email = email;
      if (phoneNumber !== user.phoneNumber) updates.phoneNumber = phoneNumber;
      if (preferredContact !== user.preferredContact) updates.preferredContact = preferredContact;
  
      await updateUserProfile(updates);
      setMessage('Profile updated successfully');
    } catch (error) {
      setError(error.message);
    }
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };

  const handleCancel = () => {
    navigate('/profile');
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <h2 className="text-2xl font-bold mb-8">Edit Profile</h2>
      {message && <p className="text-green-500 mb-4">{message}</p>}
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="font-bold block mb-2">Email</label>
          <input
            type="email"
            id="email"
            value={user.email}
            readOnly
            className="w-full p-2 border rounded bg-gray-100"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="displayName" className="font-bold block mb-2">Display Name</label>
          <input
            type="text"
            id="displayName"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="phoneNumber" className="font-bold block mb-2">Phone Number</label>
          <input
            type="tel"
            id="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneChange}
            placeholder="(123) 456-7890"
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="preferredContact" className="font-bold block mb-2">Preferred Contact Method</label>
          <select
            id="preferredContact"
            value={preferredContact}
            onChange={(e) => setPreferredContact(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select a method</option>
            <option value="email">Email</option>
            <option value="phone-call">Phone - Call</option>
            <option value="phone-text">Phone - Text</option>
          </select>
        </div>
        <div className="flex justify-between mb-4">
          <button type="button" onClick={handleCancel} className="p-2 bg-gray-300 text-black rounded">
            Cancel
          </button>
          <button type="submit" className="p-2 bg-orange-500 text-white rounded">
            Update Profile
          </button>
          
        </div>

        <div className="mt-24 bg-red-100 p-6 rounded">
          <h2 className="text-xl font-bold mb-4 text-red-800">Danger Zone</h2>
          <button 
            onClick={() => navigate('/delete-account')}
            className="w-full p-2 bg-white text-red-700 rounded border border-red-500 hover:bg-red-100"
          >
            Delete Account
          </button>
        </div>
      </form>
    </div>
  );
}

export default ProfileEdit;