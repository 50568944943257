// hooks/useCollections.js
import { useState, useEffect, useMemo, useCallback } from 'react';
import { firestore, collection, doc as firestoreDoc, getDoc, setDoc, updateDoc, deleteDoc, getDocs, query, where, arrayUnion, arrayRemove, onSnapshot, serverTimestamp } from '../firebase';

export const MAX_COLLECTIONS = 2;

export const useCollections = (user, setUserCollectionCount) => {
  const [collections, setCollections] = useState([]);
  const [sharedCollections, setSharedCollections] = useState([]);

  useEffect(() => {
    if (!user) return;

    const collectionsRef = collection(firestore, 'collections');
    const q = query(collectionsRef, where('ownerId', '==', user.uid));

    const fetchOwnCollections = async () => {
      const snapshot = await getDocs(q);
      const collectionsArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCollections(collectionsArray);
      setUserCollectionCount(collectionsArray.length);
    };

    fetchOwnCollections();

    const sharedCollectionsRef = collection(firestore, `users/${user.uid}/sharedCollections`);
    const unsubscribeShared = onSnapshot(sharedCollectionsRef, async (snapshot) => {
      const sharedCollectionsArray = await Promise.all(snapshot.docs.map(async (doc) => {
        const collectionRef = firestoreDoc(firestore, 'collections', doc.id);
        const collectionSnap = await getDoc(collectionRef);
        if (collectionSnap.exists() && collectionSnap.data().sharedWith.includes(user.uid)) {
          return {
            id: doc.id,
            ...collectionSnap.data(),
          };
        }
        return null;
      }));
      setSharedCollections(sharedCollectionsArray.filter(Boolean));
    });

    return () => {
      if (unsubscribeShared) {
        unsubscribeShared();
      }
    };
  }, [user, setUserCollectionCount]);

  const addCollection = useCallback(async (name) => {
    if (user) {
      try {
        const collectionsRef = collection(firestore, 'collections');
        const snapshot = await getDocs(query(collectionsRef, where('ownerId', '==', user.uid)));
        const currentCollections = snapshot.docs.map(doc => doc.data());

        if (!user.isPremium && currentCollections.length >= MAX_COLLECTIONS) {
          throw new Error(`You can only create a maximum of ${MAX_COLLECTIONS} collections on the free plan.`);
        }

        const newCollectionRef = firestoreDoc(collectionsRef);
        const newCollection = { 
          id: newCollectionRef.id,
          name, 
          ownerId: user.uid,
          createdAt: new Date(),
          updatedAt: new Date()
        };
        await setDoc(newCollectionRef, newCollection);
        
        // Update local state
        setCollections(prevCollections => [...prevCollections, newCollection]);
        setUserCollectionCount(prevCount => prevCount + 1);
        
        console.log('Collection added successfully');
        return newCollectionRef.id;
      } catch (error) {
        console.error('Error adding collection:', error);
        throw error;
      }
    } else {
      throw new Error('No user logged in');
    }
  }, [user, setUserCollectionCount]);

  const editCollection = useCallback(async (collectionId, newName) => {
    if (user) {
      try {
        const collectionRef = firestoreDoc(firestore, `collections/${collectionId}`);
        await updateDoc(collectionRef, { 
          name: newName,
          updatedAt: new Date()
        });
        console.log('Collection updated successfully');
      } catch (error) {
        console.error('Error updating collection:', error);
        throw new Error(`Failed to update collection: ${error.message}`);
      }
    } else {
      throw new Error('No user logged in');
    }
  }, [user]);

  const deleteCollection = useCallback(async (collectionId) => {
    if (user) {
      try {
        const collectionRef = firestoreDoc(firestore, `collections/${collectionId}`);
        await deleteDoc(collectionRef);
        
        // Update local state
        setCollections(prevCollections => prevCollections.filter(collection => collection.id !== collectionId));
        setUserCollectionCount(prevCount => prevCount - 1);
        
        console.log('Collection deleted successfully');
      } catch (error) {
        console.error('Error deleting collection:', error);
        throw new Error(`Failed to delete collection: ${error.message}`);
      }
    } else {
      throw new Error('No user logged in');
    }
  }, [user, setUserCollectionCount]);

  const findCollectionOwner = useCallback(async (collectionId) => {
    const usersRef = collection(firestore, 'users');
    const snapshot = await getDocs(usersRef);
    const users = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
    for (const user of users) {
      if (user.collections && user.collections[collectionId]) {
        return user.id;
      }
    }
  
    return null; // Return null instead of throwing an error
  }, []);

  const getCollectionDetails = useCallback(async (collectionId, ownerId = null) => {
    try {
      if (!ownerId) {
        ownerId = await findCollectionOwner(collectionId);
      }
  
      // If ownerId is still null, the collection might be directly in the 'collections' collection
      const collectionRef = firestoreDoc(firestore, `collections/${collectionId}`);
      const snapshot = await getDoc(collectionRef);
      if (snapshot.exists()) {
        const data = snapshot.data();
        console.log("Collection details fetched:", data);
        return { id: collectionId, ownerId: ownerId || data.ownerId, ...data };
      } else {
        console.warn(`Collection ${collectionId} not found in database`);
        return null;
      }
    } catch (error) {
      console.error('Error fetching Collection details:', error);
      return null;
    }
  }, [findCollectionOwner]);

  const getSharedUsers = useCallback(async (collectionId) => {
    if (user) {
      try {
        const collectionRef = firestoreDoc(firestore, `users/${user.uid}/collections/${collectionId}`);
        const collectionSnapshot = await getDoc(collectionRef);
        const collectionData = collectionSnapshot.data();

        if (collectionData && collectionData.sharedWith) {
          return Object.keys(collectionData.sharedWith);
        } else {
          return [];
        }
      } catch (error) {
        console.error('Error getting shared users:', error);
        throw new Error(`Failed to get shared users: ${error.message}`);
      }
    } else {
      throw new Error('No user logged in');
    }
  }, [user]);

  const shareCollection = useCallback(async (collectionId, sharedUserEmail) => {
    if (user) {
      try {
        // Get the shared user's ID from their email
        const usersRef = collection(firestore, 'users');
        const q = query(usersRef, where('email', '==', sharedUserEmail));
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          throw new Error('User not found');
        }
  
        const sharedUserId = querySnapshot.docs[0].id;
  
        // Check if the collection exists
        const collectionRef = firestoreDoc(firestore, `collections/${collectionId}`);
        const collectionSnapshot = await getDoc(collectionRef);
        
        if (!collectionSnapshot.exists()) {
          throw new Error('Collection not found');
        }
  
        const collectionData = collectionSnapshot.data();
  
        // Add the collection to the shared user's sharedCollections
        const sharedCollectionRef = firestoreDoc(firestore, `users/${sharedUserId}/sharedCollections/${collectionId}`);
        
        await setDoc(sharedCollectionRef, {
          name: collectionData.name,
          ownerId: user.uid
        });
  
        // Update the collection's sharedWith array
        await updateDoc(collectionRef, {
          sharedWith: arrayUnion(sharedUserId)
        });
  
        // Create a notification for the shared user
        const pendingInvitationRef = firestoreDoc(collection(firestore, 'pendingInvitations'));
        await setDoc(pendingInvitationRef, {
          collectionId,
          ownerId: user.uid,
          recipientId: sharedUserId,
          status: 'pending',
          createdAt: serverTimestamp()
        });

        // Create a notification for the shared user
        const notificationRef = firestoreDoc(collection(firestore, `users/${sharedUserId}/notifications`));
        await setDoc(notificationRef, {
          type: 'collection_invitation',
          collectionId: collectionId,
          collectionName: collectionData.name,
          sharedBy: user.uid,
          sharedByEmail: user.email,
          timestamp: serverTimestamp(),
          read: false
        });

        console.log('Collection invitation sent successfully');
      } catch (error) {
        console.error('Error sending collection invitation:', error);
        throw new Error(`Failed to send collection invitation: ${error.message}`);
      }
    } else {
      throw new Error('No user logged in');
    }
  }, [user]);

  const removeSharedAccess = useCallback(async (collectionId, userIdToRemove) => {
    if (user) {
      try {
        // Remove user from the collection's sharedWith array
        const collectionRef = firestoreDoc(firestore, `collections/${collectionId}`);
        await updateDoc(collectionRef, {
          sharedWith: arrayRemove(userIdToRemove)
        });
  
        // Remove collection from user's sharedCollections subcollection
        const sharedCollectionRef = firestoreDoc(firestore, `users/${userIdToRemove}/sharedCollections/${collectionId}`);
        await deleteDoc(sharedCollectionRef);
  
        // Remove any pending invitations
        const pendingInvitationsRef = collection(firestore, 'pendingInvitations');
        const q = query(pendingInvitationsRef, 
          where('collectionId', '==', collectionId),
          where('recipientId', '==', userIdToRemove)
        );
        const pendingInvitationsSnapshot = await getDocs(q);
        
        pendingInvitationsSnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
  
        // Remove any related notifications
        const notificationsRef = collection(firestore, `users/${userIdToRemove}/notifications`);
        const notificationsQuery = query(notificationsRef, 
          where('type', '==', 'collection_invitation'),
          where('collectionId', '==', collectionId)
        );
        const notificationsSnapshot = await getDocs(notificationsQuery);
  
        notificationsSnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
  
        // If the current user is the one being removed, update the local state
        if (user.uid === userIdToRemove) {
          setSharedCollections(prev => prev.filter(collection => collection.id !== collectionId));
        }
  
        console.log('Shared access removed successfully');
      } catch (error) {
        console.error('Error removing shared access:', error);
        throw new Error(`Failed to remove shared access: ${error.message}`);
      }
    } else {
      throw new Error('No user logged in');
    }
  }, [user, setSharedCollections]);

  const getCollectionItems = useCallback(async (ownerId, collectionId) => {
    console.log(`Fetching items for collection: ${collectionId}, owner: ${ownerId}`);
    try {
      const itemsRef = collection(firestore, `collections/${collectionId}/items`);
      const snapshot = await getDocs(itemsRef);
      const items = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), ownerId }));
      console.log(`Items fetched for collection ${collectionId}:`, items);
      return items;
    } catch (error) {
      console.error('Error fetching collection items:', error);
      throw error;
    }
  }, []);

  return useMemo(() => ({
    collections,
    sharedCollections,
    addCollection,
    editCollection,
    deleteCollection,
    getCollectionDetails,
    findCollectionOwner,
    getSharedUsers,
    shareCollection,
    removeSharedAccess,
    getCollectionItems,
  }), [collections, sharedCollections, addCollection, editCollection, deleteCollection, getCollectionDetails, findCollectionOwner, getSharedUsers, shareCollection, removeSharedAccess, getCollectionItems]);
};