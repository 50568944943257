// contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth, firestore, googleProvider, storage } from '../firebase';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithPopup, updateProfile, deleteUser, signInWithEmailAndPassword, reload, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, deleteDoc, collection, getDocs } from 'firebase/firestore';
import { ref, listAll, getMetadata } from 'firebase/storage';
import { getPremiumStatus } from '../utils/getPremiumStatus';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const calculateStorageUsage = async (uid) => {
    const storageRef = ref(storage, `itemImages/${uid}`);
    const items = await listAll(storageRef);
    let totalSize = 0;
    for (const item of items.items) {
      const metadata = await getMetadata(item);
      totalSize += metadata.size;
    }
    return totalSize / (1024 * 1024); // Convert to MB
  };

  const refreshUser = async () => {
    if (auth.currentUser) {
      await reload(auth.currentUser);
      const userRef = doc(firestore, `users/${auth.currentUser.uid}`);
      const snapshot = await getDoc(userRef);
      const userData = snapshot.data();
      const isPremium = await getPremiumStatus(auth.currentUser.uid);
      const storageUsage = await calculateStorageUsage(auth.currentUser.uid);
      await updateDoc(userRef, { storageUsage });
      setUser({ ...auth.currentUser, ...userData, isPremium, storageUsage });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userRef = doc(firestore, `users/${firebaseUser.uid}`);
        const snapshot = await getDoc(userRef);
        let userData = snapshot.data();
        
        if (!userData) {
          userData = {
            email: firebaseUser.email,
            displayName: firebaseUser.displayName,
            photoURL: firebaseUser.photoURL,
            storageUsage: 0,
          };
          await setDoc(userRef, userData);
        }
        
        let isPremium = false;
        try {
          isPremium = await getPremiumStatus(firebaseUser.uid);
        } catch (error) {
          console.error("Error fetching premium status:", error);
        }

        const storageUsage = await calculateStorageUsage(firebaseUser.uid);
        await updateDoc(userRef, { storageUsage });

        setUser({ ...firebaseUser, ...userData, isPremium, storageUsage });
      } else {
        setUser(null);
      }
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, []);

  const registerUser = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Store user email and other initial data in Firestore
      await setDoc(doc(firestore, `users/${user.uid}`), {
        email: email,
        displayName: '',
        phoneNumber: '',
        preferredContact: ''
      });
      
      // Send verification email immediately
      await sendEmailVerification(user);
      
      return user;
    } catch (error) {
      console.error("Error registering user:", error);
      throw error;
    }
  };

  const updateUserProfile = async (updates) => {
    if (user) {
      try {
        const userRef = doc(firestore, `users/${user.uid}`);
        
        // Only update the fields that are provided in the updates object
        const updatesToSave = {};
        if (updates.displayName !== undefined) updatesToSave.displayName = updates.displayName;
        if (updates.email !== undefined) updatesToSave.email = updates.email;
        if (updates.phoneNumber !== undefined) updatesToSave.phoneNumber = updates.phoneNumber;
        if (updates.preferredContact !== undefined) updatesToSave.preferredContact = updates.preferredContact;
  
        // Use updateDoc instead of setDoc to only change specified fields
        await updateDoc(userRef, updatesToSave);
  
        if (updates.displayName) {
          await updateProfile(auth.currentUser, { displayName: updates.displayName });
        }
  
        // Refresh user data
        const updatedSnapshot = await getDoc(userRef);
        const updatedUserData = updatedSnapshot.data();
        setUser({ ...user, ...updatedUserData });
      } catch (error) {
        console.error("Error updating user profile:", error);
        throw error;
      }
    } else {
      throw new Error('No user logged in');
    }
  };

  const deleteUserAccount = async (password) => {
    if (user) {
      try {
        // Re-authenticate the user
        const credential = await signInWithEmailAndPassword(auth, user.email, password);

        // Delete user data from Firestore
        await deleteDoc(doc(firestore, `users/${user.uid}`));

        // Delete shared collections references
        const sharedCollectionsRef = collection(firestore, 'users');
        const sharedCollectionsSnapshot = await getDocs(sharedCollectionsRef);
        const allUsers = sharedCollectionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        for (const userData of allUsers) {
          if (userData.sharedCollections) {
            for (const collectionId in userData.sharedCollections) {
              if (userData.sharedCollections[collectionId].ownerId === user.uid) {
                await deleteDoc(doc(firestore, `users/${userData.id}/sharedCollections/${collectionId}`));
              }
            }
          }
        }

        // Delete the user's authentication account
        await deleteUser(credential.user);

        setUser(null);
      } catch (error) {
        console.error("Error deleting user account:", error);
        throw error;
      }
    } else {
      throw new Error('No user logged in');
    }
  };

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      // Check if the user already exists in Firestore
      const userRef = doc(firestore, `users/${user.uid}`);
      const userSnap = await getDoc(userRef);
  
      if (!userSnap.exists()) {
        // If the user doesn't exist, create a new user document
        await setDoc(userRef, {
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          // Add any other fields you want to store
        });
      }
  
      return user;
    } catch (error) {
      console.error("Error signing in with Google:", error);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    signInWithGoogle,
    registerUser,
    updateUserProfile,
    deleteUserAccount,
    isEmailVerified: user ? user.emailVerified : false,
    refreshUser,
    calculateStorageUsage,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};