// components/PricingPage.js
import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet';
import { HiCheck } from "react-icons/hi";
import { getCheckoutUrl } from '../utils/stripePayment';
import { firestore } from '../firebase';

function PricingPage() {
  const [billingCycle, setBillingCycle] = useState('monthly');
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleUpgrade = async (priceId) => {
    if (!user) {
      navigate('/register');
      return;
    }
  
    setIsLoading(true); // Set loading to true when starting the process
    try {
      const checkoutUrl = await getCheckoutUrl(firebaseApp, priceId);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setIsLoading(false); // Set loading back to false if there's an error
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4">
      <Helmet>
        <title>Lendovu Pricing - Choose Your Perfect Plan | Free and Pro Options</title>
        <meta name="description" content="Explore Lendovu's flexible pricing options. From our free plan for casual users to our pro plan for super sharers, find the perfect fit for your sharing needs." />
        <link rel="canonical" href="https://www.lendovu.com/pricing" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "PriceSpecification",
              "name": "Lendovu Pricing Plans",
              "description": "Choose between our Free plan for casual users or Pro plan for super sharers.",
              "offers": [
                {
                  "@type": "Offer",
                  "name": "Free Plan",
                  "price": "0",
                  "priceCurrency": "USD",
                  "description": "Perfect for casual users with up to 2 collections and 20 items."
                },
                {
                  "@type": "Offer",
                  "name": "Pro Plan (Monthly)",
                  "price": "1.99",
                  "priceCurrency": "USD",
                  "description": "Ideal for super sharers with unlimited collections and items."
                },
                {
                  "@type": "Offer",
                  "name": "Pro Plan (Yearly)",
                  "price": "19.99",
                  "priceCurrency": "USD",
                  "description": "Ideal for super sharers with unlimited collections and items, billed annually."
                }
              ]
            }
          `}
        </script>
      </Helmet>

      <h1 className="text-4xl font-bold text-center mb-4">Pricing</h1>
      <p className="text-xl text-center mb-8">
        Choose the plan that fits your lifestyle. Whether you're just getting<br />
        started or ready to share to the max, Lendovu has a plan for you.
      </p>

      <div className="flex justify-center mb-8">
        <button 
          className={`px-4 py-2 rounded-l-lg ${billingCycle === 'monthly' ? 'bg-orange-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setBillingCycle('monthly')}
        >
          Monthly billing
        </button>
        <button 
          className={`px-4 py-2 rounded-r-lg ${billingCycle === 'yearly' ? 'bg-orange-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setBillingCycle('yearly')}
        >
          Yearly billing
        </button>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <PricingCard 
          title="Perfect for Casual Users"
          price="FREE"
          features={[
            { name: "2 Collections", description: "Organize up to two different categories of items." },
            { name: "20 Items", description: "Keep track of up to 20 personal items." },
            { name: "20MB of Photo Storage", description: "Store images of your items with up to 20MB of space." },
            { name: "Unlimited Sharing", description: "Share your collections with friends and family without limits." },
          ]}
          ctaText="Get Started for Free"
          ctaLink="/register"
          isLoading={false}  // Add this line
        />
        <PricingCard 
          title="Ideal for Super Sharers"
          price={billingCycle === 'monthly' ? "$1.99" : "$19.99"}
          cycle={billingCycle === 'monthly' ? "/month" : "/year"}
          features={[
            { name: "Unlimited Collections", description: "Create as many collections as you need to organize your belongings." },
            { name: "Unlimited Items", description: "Track an unlimited number of items, perfect for extensive inventories." },
            { name: "Unlimited Photo Storage", description: "Upload as many photos as you need, without worrying about space." },
            { name: "Unlimited Sharing", description: "Continue sharing with your network without any restrictions." },
            { name: "Priority Support", description: "Get faster assistance with any issues or questions you may have." },
          ]}
          ctaText="Sign Up with Pro"
          onUpgrade={() => handleUpgrade(billingCycle === 'monthly' ? 'price_1PgsQqC2MiACY3xSuWxWSZNy' : 'price_1PkqWrC2MiACY3xSMAVV4fqb')}
          featured={true}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

function PricingCard({ title, price, cycle, features, ctaText, ctaLink, onUpgrade, featured = false, isLoading }) {
  return (
    <div className={`bg-white p-8 rounded-lg shadow-md ${featured ? 'border-2 border-orange-500' : ''}`}>
      <h2 className="text-2xl font-semibold mb-4">{title}</h2>
      <ul className="mb-6">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start mb-4">
            <HiCheck className="text-green-500 w-6 h-6 mr-2 flex-shrink-0" />
            <div>
              <strong>{feature.name}</strong>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          </li>
        ))}
      </ul>
      <div className="text-3xl font-bold mb-6">
        {price}<span className="text-xl font-normal text-gray-600">{cycle}</span>
      </div>
      {onUpgrade ? (
        <button 
          onClick={onUpgrade}
          disabled={isLoading}
          className={`w-full text-center py-2 px-4 rounded ${
            featured ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-800'
          } ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isLoading ? 'Processing...' : ctaText}
        </button>
      ) : (
        <Link 
          to={ctaLink}
          className={`block text-center py-2 px-4 rounded ${
            featured ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-800'
          }`}
        >
          {ctaText}
        </Link>
      )}
    </div>
  );
}

export default PricingPage;