// App.js
import React, { useState, useEffect } from 'react';
import ErrorBoundary from './ErrorBoundary';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { CollectionProvider } from './contexts/CollectionContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContent from './components/AppContent';
import CookieConsent from './components/CookieConsent';
import { checkCookieConsent } from './utils/cookieConsent';

function App() {
  const [cookieStatus, setCookieStatus] = useState(checkCookieConsent() ? 'accepted' : 'unknown');

  const handleCookieStatusChange = (status) => {
    setCookieStatus(status);
    // Here you can add logic to enable/disable features based on cookie status
  };

  useEffect(() => {
    // Initialize or disable features based on cookie status
    if (cookieStatus === 'accepted') {
      // Initialize analytics, set cookies, etc.
    } else if (cookieStatus === 'rejected') {
      // Disable analytics, clear non-essential cookies, etc.
    }
  }, [cookieStatus]);

  return (
    <ErrorBoundary>
      <AuthProvider>
        <CollectionProvider>
          <Router>
            <AppContent />
            <ToastContainer />
            <CookieConsent onStatusChange={handleCookieStatusChange} />
          </Router>
        </CollectionProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;