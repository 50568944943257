import { useCallback, useEffect, useState } from 'react';
import { 
  firestore, 
  collection, 
  doc, 
  getDoc, 
  setDoc, 
  updateDoc, 
  deleteDoc, 
  query, 
  where, 
  getDocs, 
  onSnapshot, 
  serverTimestamp,
  arrayRemove 
} from '../firebase';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

export const MAX_ITEMS = 20;

export const useItems = (user, sharedCollections, setUserItemCount) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      const itemsRef = collection(firestore, 'items');
      const q = query(itemsRef, where('ownerId', '==', user.uid));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const items = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setItems(items);
        setUserItemCount(items.length);
        setLoading(false);
      });

      return () => unsubscribe();
    }
  }, [user, setUserItemCount]);

  const getAllItems = useCallback(async () => {
    if (!user) return { userCreatedItems: [], allItems: [] };
    try {
      const itemsRef = collection(firestore, 'items');
      const userItemsQuery = query(itemsRef, where('ownerId', '==', user.uid));
      const userItemsSnapshot = await getDocs(userItemsQuery);
      const userItems = userItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      let allItems = [...userItems];

      // Fetch shared items
      if (sharedCollections && sharedCollections.length > 0) {
        const sharedItemsQuery = query(itemsRef, where('collections', 'array-contains-any', sharedCollections.map(sc => sc.id)));
        const sharedItemsSnapshot = await getDocs(sharedItemsQuery);
        const sharedItems = sharedItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), isShared: true }));
        allItems = allItems.concat(sharedItems);
      }

      return { userCreatedItems: userItems, allItems };
    } catch (error) {
      console.error('Error fetching items:', error);
      throw error;
    }
  }, [user, sharedCollections]);

  const addItem = async (item, collectionIds) => {
    if (!user) throw new Error('No user logged in');
    
    try {
      const itemsRef = collection(firestore, 'items');
      const newItemRef = doc(itemsRef);
      const timestamp = serverTimestamp();
      
      let imageUrl = null;
      if (item.image) {
        try {
          const imageRef = ref(storage, `itemImages/${user.uid}/${newItemRef.id}`);
          await uploadBytes(imageRef, item.image);
          imageUrl = await getDownloadURL(imageRef);
        } catch (storageError) {
          console.error('Error uploading image:', storageError);
          throw new Error(`Failed to upload image: ${storageError.message}`);
        }
      }
  
      const newItemData = {
        name: item.name,
        description: item.description,
        imageUrl,
        collections: collectionIds,
        ownerId: user.uid,
        createdAt: timestamp,
        updatedAt: timestamp
      };
  
      await setDoc(newItemRef, newItemData);
  
      console.log('Item added successfully');
      return newItemRef.id;
    } catch (error) {
      console.error('Error adding item:', error);
      throw new Error(`Failed to add item: ${error.message}`);
    }
  };

  const editItem = async (itemId, updatedItem, newCollectionIds) => {
    if (!user) throw new Error('No user logged in');
    
    try {
      console.log('Editing item:', itemId);
      console.log('Updated item data:', updatedItem);
  
      const itemRef = doc(firestore, 'items', itemId);
      const itemSnapshot = await getDoc(itemRef);
      
      if (!itemSnapshot.exists()) {
        throw new Error('Item not found');
      }
  
      const currentItemData = itemSnapshot.data();
      const timestamp = serverTimestamp();
      
      let imageUrl = currentItemData.imageUrl;
    
      // Handle image update
      if (updatedItem.image instanceof File) {
        console.log('New image file detected, uploading...');
        const imageRef = ref(storage, `itemImages/${user.uid}/${itemId}`);
        await uploadBytes(imageRef, updatedItem.image);
        imageUrl = await getDownloadURL(imageRef);
        console.log('New image uploaded, URL:', imageUrl);
      } else if (updatedItem.imageUrl === null) {
        console.log('Image removal detected');
        if (currentItemData.imageUrl) {
          try {
            const imageRef = ref(storage, currentItemData.imageUrl);
            await deleteObject(imageRef);
            console.log('Old image deleted successfully');
          } catch (deleteError) {
            if (deleteError.code === 'storage/object-not-found') {
              console.log('Image already deleted or not found:', currentItemData.imageUrl);
            } else {
              console.error('Error deleting old image:', deleteError);
            }
          }
        }
        imageUrl = null;
      } else {
        console.log('No image change detected');
      }
    
      const updateData = {
        name: updatedItem.name,
        description: updatedItem.description,
        collections: newCollectionIds,
        imageUrl: imageUrl,
        updatedAt: timestamp
      };
    
      console.log('Final update data:', updateData);
      await updateDoc(itemRef, updateData);
    
      console.log('Item updated successfully');
      return itemId;
    } catch (error) {
      console.error('Error updating item:', error);
      throw new Error(`Failed to update item: ${error.message}`);
    }
  };

  const deleteItem = useCallback(async (itemId) => {
    if (!user) throw new Error('No user logged in');
    
    try {
      const itemRef = doc(firestore, 'items', itemId);
      const itemSnapshot = await getDoc(itemRef);
      
      if (!itemSnapshot.exists()) {
        throw new Error('Item not found in database');
      }
  
      const itemData = itemSnapshot.data();
  
      // Delete image from storage if it exists
      if (itemData.imageUrl) {
        const imageRef = storageRef(storage, itemData.imageUrl);
        try {
          await deleteObject(imageRef);
          console.log('Image deleted successfully from storage');
        } catch (storageError) {
          console.error('Error deleting image from storage:', storageError);
          // Continue with deletion even if image deletion fails
        }
      }
  
      // Remove the item from all collections it was in
      if (itemData.collections) {
        for (const collectionId of itemData.collections) {
          try {
            const collectionRef = doc(firestore, 'collections', collectionId);
            const collectionSnapshot = await getDoc(collectionRef);
            if (collectionSnapshot.exists()) {
              await updateDoc(collectionRef, {
                items: arrayRemove(itemId)
              });
            } else {
              console.warn(`Collection ${collectionId} not found, skipping update.`);
            }
          } catch (collectionError) {
            console.error(`Error updating collection ${collectionId}:`, collectionError);
            // Continue with deletion even if collection update fails
          }
        }
      }
  
      // Delete the item document
      await deleteDoc(itemRef);
  
      console.log('Item deleted successfully');
    } catch (error) {
      console.error('Error deleting item:', error);
      throw error;
    }
  }, [user]);

  const getCollectionItems = useCallback(async (ownerId, collectionId) => {
    console.log(`Fetching items for owner ${ownerId}, collection ${collectionId}`);
    try {
      const isOwner = ownerId === user.uid;
      const isShared = sharedCollections && sharedCollections.some(lib => lib.id === collectionId);

      if (isOwner || isShared) {
        const collectionItemsRef = collection(firestore, `users/${ownerId}/collections/${collectionId}/items`);
        const collectionItemsSnapshot = await getDocs(collectionItemsRef);
        
        if (!collectionItemsSnapshot.empty) {
          const itemIds = collectionItemsSnapshot.docs.map(doc => doc.id);
          
          const itemDetails = await Promise.all(
            itemIds.map(async (itemId) => {
              const itemRef = doc(firestore, `users/${ownerId}/items/${itemId}`);
              const itemSnapshot = await getDoc(itemRef);
              return { id: itemId, ...itemSnapshot.data(), ownerId };
            })
          );
          
          console.log("Item details:", itemDetails);
          return itemDetails;
        } else {
          console.log("No items found for this collection");
          return [];
        }
      } else {
        console.error("User does not have access to this collection");
        throw new Error("Access denied");
      }
    } catch (error) {
      console.error('Error fetching collection items:', error);
      throw error;
    }
  }, [user, sharedCollections]);

  const getItemDetails = async (itemId) => {
    try {
      const itemRef = doc(firestore, 'items', itemId);
      const snapshot = await getDoc(itemRef);
      if (snapshot.exists()) {
        const data = snapshot.data();
        console.log("Item details fetched:", data);
        return { id: itemId, ...data };
      } else {
        console.error("Item not found in database");
        throw new Error('Item not found in database');
      }
    } catch (error) {
      console.error('Error fetching item details:', error);
      throw error;
    }
  };

  const associateItemWithCollection = async (itemId, sourceCollectionId, targetCollectionId) => {
    if (!user) throw new Error('No user logged in');
    
    try {
      const itemRef = doc(firestore, `users/${user.uid}/items/${itemId}`);
      const itemSnapshot = await getDoc(itemRef);
      if (itemSnapshot.exists()) {
        const itemData = itemSnapshot.data();
        const targetItemRef = doc(firestore, `users/${user.uid}/collections/${targetCollectionId}/items/${itemId}`);
        await setDoc(targetItemRef, { exists: true });
        
        const updatedCollections = [...(itemData.collections || []), targetCollectionId];
        await updateDoc(itemRef, { collections: updatedCollections });
        
        console.log('Item associated with collection successfully');
      } else {
        throw new Error('Item not found');
      }
    } catch (error) {
      console.error('Error associating item with collection:', error);
      throw error;
    }
  };

  return {
    items,
    loading,
    getAllItems,
    addItem,
    editItem,
    deleteItem,
    getCollectionItems,
    getItemDetails,
    associateItemWithCollection,
  };
};