import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful, navigating to /discover');
      navigate('/discover');
    } catch (error) {
      console.error('Login error:', error);
      // Use a generic error message
      setError("Invalid email or password. Please try again.");
    }
  };

  const { signInWithGoogle } = useContext(AuthContext);
  
  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      navigate('/discover');
    } catch (error) {
      console.error('Google sign-in error:', error);
      setError(error.message);
    }
  };

  return (
    <div className="p-8 max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4">Login</h2>
      
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button type="submit" className="w-full p-2 bg-orange-500 text-white rounded">
          Login
        </button>
      </form>
      <button 
        onClick={handleGoogleSignIn}
        className="w-full p-2 mt-2 bg-white border border-gray-300 rounded flex items-center justify-center"
      >
        <FcGoogle className="mr-2" /> Sign in with Google
      </button>
      <div className="mt-4">
        <Link to="/register" className="text-orange-500">Don't have an account? Register</Link>
      </div>
      <div className="mt-2">
        <Link to="/pass-reset" className="text-orange-500">Forgot password?</Link>
      </div>
    </div>
  );
}

export default Login;