// components/Search.js
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { CollectionContext } from '../contexts/CollectionContext';
import { HiMagnifyingGlass, HiXMark } from "react-icons/hi2";
import { useSearch } from '../hooks/useSearch';
import BackButton from './BackButton';
import ItemList from './ItemList';
import { Link } from 'react-router-dom';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState({ items: [], collections: [] });
  const [loading, setLoading] = useState(false);
  const searchInputRef = useRef(null);
  const { getAllItems, collections } = useContext(CollectionContext);
  const { initializeSearch, performSearch } = useSearch();

  useEffect(() => {
    const initSearch = async () => {
      const { allItems } = await getAllItems();
      initializeSearch(allItems, collections);
    };
    initSearch();
  }, [getAllItems, collections, initializeSearch]);

  const handleSearch = useCallback(async () => {
    if (searchTerm.trim() !== '') {
      setLoading(true);
      try {
        console.log('Performing search with term:', searchTerm);
        const searchResults = await performSearch(searchTerm);
        console.log('Search results:', searchResults);
        setResults({
          items: searchResults.items || [],
          collections: searchResults.collections || []
        });
      } catch (error) {
        console.error('Error performing search:', error);
        setResults({ items: [], collections: [] });
      } finally {
        setLoading(false);
      }
    } else {
      setResults({ items: [], collections: [] });
    }
  }, [searchTerm, performSearch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 300); // 300ms delay for debounce

    return () => clearTimeout(delayDebounceFn);
  }, [handleSearch]);

  const handleClear = () => {
    setSearchTerm('');
    setResults({ items: [], collections: [] });
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <BackButton />
      <h2 className="text-2xl font-bold mb-4">Search</h2>
      <div className="mb-4">
        <div className="flex items-center relative">
          <input
            ref={searchInputRef}
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search for items or collections"
            className="flex-grow p-2 border rounded-l"
          />
          {searchTerm && (
            <button
              onClick={handleClear}
              className="clear-search-btn bg-white text-gray-600 p-2 flex items-center absolute"
              aria-label="Clear search"
            >
              <HiXMark className='mr-1' /> Clear
            </button>
          )}
          <button className="bg-orange-500 text-white p-2 rounded-r text-2xl">
            <HiMagnifyingGlass />
          </button>
        </div>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h3 className="text-xl font-bold mb-2">Items</h3>
          {results.items.length === 0 ? (
            <p>No matching items found</p>
          ) : (
            <>
              <p>Found {results.items.length} items</p>
              <ItemList items={results.items} />
            </>
          )}

          <h3 className="text-xl font-bold mt-6 mb-2">Collections</h3>
          {results.collections.length === 0 ? (
            <p>No matching collections found</p>
          ) : (
            <>
              <p>Found {results.collections.length} collections</p>
              <ul className="list-disc pl-5">
                {results.collections.map(collection => (
                  <li key={collection.id} className="mb-2">
                    <Link to={`/collection/${collection.id}`} className="text-orange-500 hover:underline">
                      {collection.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Search;