// components/DeleteAccount.js

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { showToast } from '../utils/toast';
import BackButton from './BackButton';

function DeleteAccount() {
  const [password, setPassword] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const { deleteUserAccount } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleDelete = async (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        setIsDeleting(true);
        await deleteUserAccount(password);
        showToast.success('Your account has been successfully deleted.');
        navigate('/');
      } catch (error) {
        showToast.error(`Failed to delete account: ${error.message}`);
        setIsDeleting(false);
      }
    }
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <BackButton />
      <h2 className="text-2xl font-bold mb-4">Delete Account</h2>
      <p className="mb-4 text-red-500">Warning: This action is irreversible. All your data will be permanently deleted.</p>
      <form onSubmit={handleDelete}>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2">Confirm your password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button 
          type="submit" 
          className="w-full p-2 bg-white text-red-700 border border-red-500 hover:bg-red-200 rounded"
          disabled={isDeleting}
        >
          {isDeleting ? 'Deleting...' : 'Delete My Account'}
        </button>
      </form>
    </div>
  );
}

export default DeleteAccount;