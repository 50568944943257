// components/AddItem.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CollectionContext } from '../contexts/CollectionContext';
import { AuthContext } from '../contexts/AuthContext';
import ImageUpload from './ImageUpload';
import { showToast } from '../utils/toast';
import { HiX } from "react-icons/hi";
import { MAX_ITEMS } from '../hooks/useItems';

function AddItem({ onClose, preSelectedCollection }) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { addItem, collections, userItemCount } = useContext(CollectionContext);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (preSelectedCollection) {
      setSelectedCollections([preSelectedCollection]);
    }
  }, [preSelectedCollection]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user.emailVerified) {
      showToast.warning(
        <div>
          Please verify your email before adding items.{' '}
          <Link to="/verify-email" className="text-blue-500 underline">
            Verify email
          </Link>
        </div>
      );
      return;
    }

    if (!user.isPremium && userItemCount >= MAX_ITEMS) {
      showToast.warning(`You can only create a maximum of ${MAX_ITEMS} items on the free plan.`);
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      const newItemId = await addItem({ name, description, image }, selectedCollections);
      showToast.success('Item added successfully');
      onClose();
      navigate(`/item/${newItemId}`);
    } catch (error) {
      showToast.error('Failed to add item. Please try again.');
      console.error('Error adding item:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageSelected = (file) => {
    setImage(file);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Add New Item</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <HiX className="w-6 h-6" />
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <ImageUpload onImageSelected={handleImageSelected} />
        </div>
        <div className="mb-4">
          <label htmlFor="name" className="block mb-2 font-bold">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block mb-2 font-bold">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-bold">Collections</label>
          {collections.map(collection => (
            <div key={collection.id}>
              <input
                type="checkbox"
                id={`collection-${collection.id}`}
                value={collection.id}
                checked={selectedCollections.includes(collection.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedCollections(prev => [...prev, collection.id]);
                  } else {
                    setSelectedCollections(prev => prev.filter(id => id !== collection.id));
                  }
                }}
              />
              <label htmlFor={`collection-${collection.id}`} className="ml-2">{collection.name}</label>
            </div>
          ))}
        </div>
        
        {error && <p className="text-red-500 mb-4">{error}</p>}
        
        {!user.isPremium && userItemCount >= MAX_ITEMS ? (
          <div className="mb-4">
            <p className="text-red-500 mb-2">You have reached the maximum number of items allowed on the free plan.</p>
            <Link 
              to="/upgrade"
              className="block text-center py-2 px-4 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors"
            >
              Upgrade to Pro for Unlimited Items
            </Link>
          </div>
        ) : (
          <button 
            type="submit"
            className="w-full p-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors"
            disabled={isLoading}
          >
            {isLoading ? 'Adding...' : 'Add Item'}
          </button>
        )}
      </form>
    </div>
  );
}

export default AddItem;