// components/UpgradeSuccessPage.js
import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { getPremiumStatus } from '../utils/getPremiumStatus';
import { firestore } from '../firebase';

function UpgradeSuccessPage() {
  const { refreshUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUpgrade = async () => {
      try {
        const isPremium = await getPremiumStatus(firebaseApp);
        
        if (isPremium) {
          await refreshUser();
          navigate('/discover');
        } else {
          navigate('/pricing');
        }
      } catch (error) {
        console.error('Error:', error);
        navigate('/pricing');
      }
    };

    verifyUpgrade();
  }, [refreshUser, navigate]);

  return (
    <div className="max-w-md mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Processing Your Upgrade</h2>
      <p>Please wait while we confirm your payment and upgrade your account...</p>
    </div>
  );
}

export default UpgradeSuccessPage;