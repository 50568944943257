import { useCallback, useContext, useState, useEffect } from 'react';
import { doc, getDoc, setDoc, deleteDoc, collection, getDocs, firestore } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';

export const useNotifications = () => {
  const { user } = useContext(AuthContext);
  const [invitations, setInvitations] = useState([]);

  const getCollectionDetails = useCallback(async (ownerId, collectionId) => {
    const collectionRef = doc(firestore, `users/${ownerId}/collections/${collectionId}`);
    const snapshot = await getDoc(collectionRef);
    return snapshot.data();
  }, []);

  const getPendingInvitations = useCallback(async () => {
    if (user) {
      const invitationsRef = collection(firestore, `users/${user.uid}/pendingInvitations`);
      const snapshot = await getDocs(invitationsRef);
      const invitations = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const invitationsWithDetails = await Promise.all(
        invitations.map(async (invitation) => {
          const collectionDetails = await getCollectionDetails(invitation.ownerId, invitation.collectionId);
          return {
            ...invitation,
            collectionName: collectionDetails ? collectionDetails.name : 'Unknown Collection'
          };
        })
      );

      return invitationsWithDetails;
    }
    return [];
  }, [user, getCollectionDetails]);

  useEffect(() => {
    const fetchInvitations = async () => {
      if (user) {
        const pendingInvitations = await getPendingInvitations();
        setInvitations(pendingInvitations);
      }
    };
    fetchInvitations();
  }, [user, getPendingInvitations]);

  const acceptCollectionInvitation = useCallback(async (invitationId) => {
    if (user) {
      const invitationRef = doc(firestore, `users/${user.uid}/pendingInvitations/${invitationId}`);
      const snapshot = await getDoc(invitationRef);
      const invitation = snapshot.data();

      if (invitation) {
        const collectionRef = doc(firestore, `users/${invitation.ownerId}/collections/${invitation.collectionId}`);
        const collectionSnapshot = await getDoc(collectionRef);
        const collectionDetails = collectionSnapshot.data();

        if (collectionDetails) {
          // Add to sharedCollections
          await setDoc(doc(firestore, `users/${user.uid}/collections/${invitation.collectionId}`), {
            ownerId: invitation.ownerId,
            name: collectionDetails.name || 'Unnamed Collection',
          });

          // Remove the invitation from the user's pending invitations
          await deleteDoc(invitationRef);

          // Remove the invitation from the collection owner's pending invitations
          const ownerPendingInvitationRef = doc(firestore, `users/${invitation.ownerId}/collections/${invitation.collectionId}/pendingInvitations/${user.uid}`);
          await deleteDoc(ownerPendingInvitationRef);

          // Add to collections sharedWith
          const sharedWithRef = doc(firestore, `users/${invitation.ownerId}/collections/${invitation.collectionId}/sharedWith/${user.uid}`);
          await setDoc(sharedWithRef, { shared: true });

          // Update local state
          setInvitations(prev => prev.filter(inv => inv.id !== invitationId));
        } else {
          console.error('Collection no longer exists');
        }
      }
    }
  }, [user]);

  const declineCollectionInvitation = useCallback(async (invitationId) => {
    if (user) {
      const invitationRef = doc(firestore, `users/${user.uid}/pendingInvitations/${invitationId}`);
      const snapshot = await getDoc(invitationRef);
      const invitation = snapshot.data();

      if (invitation) {
        // Remove the invitation from the user's pending invitations
        await deleteDoc(invitationRef);

        // Remove the invitation from the collection owner's pending invitations
        const ownerPendingInvitationRef = doc(firestore, `users/${invitation.ownerId}/collections/${invitation.collectionId}/pendingInvitations/${user.uid}`);
        await deleteDoc(ownerPendingInvitationRef);

        // Update local state
        setInvitations(prev => prev.filter(inv => inv.id !== invitationId));
      }
    }
  }, [user]);

  return {
    invitations,
    acceptCollectionInvitation,
    declineCollectionInvitation,
  };
};