// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore, collection, doc, setDoc, getDoc, updateDoc, deleteDoc, query, where, getDocs, serverTimestamp, onSnapshot, arrayRemove, arrayUnion, orderBy } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyA3i8_zbi7C0Ru-jXuKYgyYakeXpXqaCm4",
  authDomain: "lendovuapp.firebaseapp.com",
  projectId: "lendovuapp",
  storageBucket: "lendovuapp.appspot.com",
  messagingSenderId: "589766337625",
  appId: "1:589766337625:web:5ff90a29196b9321c93d48",
  measurementId: "G-60ZNGP3VX3"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const googleProvider = new GoogleAuthProvider();
const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);

export const onAuthStateChange = (callback) => {
  return onAuthStateChanged(auth, (user) => {
    callback(user);
  });
};

export { 
  firebaseApp,
  auth, 
  googleProvider,
  firestore,
  storage, 
  functions,
  getFunctions,
  httpsCallable,
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
  onSnapshot,
  arrayRemove,
  arrayUnion,
  orderBy
};