import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { storage } from '../firebase';
import { ref, getDownloadURL } from 'firebase/storage';

const ItemList = ({ items, loading, error, viewMode = 'grid' }) => {
  const [imageUrls, setImageUrls] = useState({});
  const [imageErrors, setImageErrors] = useState({});

  useEffect(() => {
    const fetchImageUrls = async () => {
      const urls = {};
      const errors = {};
      for (const item of items) {
        if (item.imageUrl) {
          try {
            console.log(`Attempting to fetch image for item ${item.id}: ${item.imageUrl}`);
            const imageRef = ref(storage, item.imageUrl);
            const url = await getDownloadURL(imageRef);
            console.log(`Successfully fetched image for item ${item.id}`);
            urls[item.id] = url;
          } catch (error) {
            console.error(`Error fetching image for item ${item.id}:`, error);
            errors[item.id] = error.message;
          }
        }
      }
      setImageUrls(urls);
      setImageErrors(errors);
    };

    if (!loading && items.length > 0) {
      fetchImageUrls();
    }
  }, [items, loading]);

  const ItemSkeleton = () => (
    <div className="border rounded p-4">
      <Skeleton width={150} height={150} className="mb-2" />
      <Skeleton width={150} height={20} />
      <Skeleton count={2} />
    </div>
  );

  if (error) return <div className="text-center mt-8 text-red-500">Error: {error}</div>;

  return (
    <div>
      {loading ? (
        <div className={`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4`}>
          {[...Array(5)].map((_, index) => (
            <ItemSkeleton key={index} />
          ))}
        </div>
      ) : items.length === 0 ? (
        <p>No items found</p>
      ) : (
        <ul className={`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4`}>
          {items.map(item => (
            <li key={item.id} className="border rounded-lg p-2">
              <Link to={`/item/${item.id}`} className="block h-full flex flex-col">
                {imageUrls[item.id] ? (
                  <img 
                    src={imageUrls[item.id]} 
                    alt={item.name} 
                    className="w-full h-32 object-cover mb-2 rounded"
                  />
                ) : (
                  <div className="w-full h-32 bg-gray-200 flex items-center justify-center mb-2 rounded">
                    {imageErrors[item.id] ? (
                      <span className="text-xs text-red-500 text-center p-2">
                        Error loading image:<br />{imageErrors[item.id]}
                      </span>
                    ) : (
                      "No Image"
                    )}
                  </div>
                )}
                <h3 className="font-semibold text-sm truncate">{item.name}</h3>
                <p className="text-xs text-gray-600 mt-auto truncate">{item.description}</p>
                {item.isShared && (
                  <span className="text-xs bg-orange-100 text-orange-800 px-2 py-1 rounded mt-1 inline-block">
                    Shared
                  </span>
                )}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ItemList;