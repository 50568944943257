// components/CookieConsent.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getCookieConsent, setCookieConsent } from '../utils/cookieConsent';

const CookieConsent = ({ onStatusChange }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = getCookieConsent();
    if (consent === null) {
      setShowBanner(true);
    } else {
      onStatusChange(consent);
    }
  }, [onStatusChange]);

  const handleAccept = () => {
    setCookieConsent('accepted');
    setShowBanner(false);
    onStatusChange('accepted');
  };

  const handleReject = () => {
    setCookieConsent('rejected');
    setShowBanner(false);
    onStatusChange('rejected');
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-notice fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 z-50">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <p className="mr-4 mb-2 md:mb-0">
          We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
          <Link to="/privacy" className="underline ml-2">Learn more</Link>
        </p>
        <div>
          <button
            onClick={handleAccept}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Accept
          </button>
          <button
            onClick={handleReject}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;