// components/ShareCollectionModal.js
import React, { useState, useEffect, useContext } from 'react';
import Modal from './Modal';
import { HiX } from "react-icons/hi";
import { CollectionContext } from '../contexts/CollectionContext';
import { firestore, doc, getDoc, updateDoc, arrayUnion, arrayRemove } from '../firebase';

const ShareCollectionModal = ({ isOpen, onClose, collectionId, sharedUsers, onShareCollection, onRemoveAccess }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const { getPendingInvitations } = useContext(CollectionContext);

  useEffect(() => {
    const fetchPendingInvitations = async () => {
      if (isOpen && collectionId) {
        const invitations = await getPendingInvitations(collectionId);
        setPendingInvitations(invitations);
      }
    };

    fetchPendingInvitations();
  }, [isOpen, collectionId, getPendingInvitations]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await onShareCollection(email);
      setEmail('');
      // Refresh pending invitations
      const invitations = await getPendingInvitations(collectionId);
      setPendingInvitations(invitations);
    } catch (error) {
      setError(error.message || 'An error occurred while sharing the collection');
    }
  };

  const handleCancelInvitation = async (invitationId) => {
    try {
      // Get the invitation details before deleting
      const invitationRef = doc(firestore, 'pendingInvitations', invitationId);
      const invitationSnap = await getDoc(invitationRef);
      const invitationData = invitationSnap.data();
  
      // Delete the invitation
      await deleteDoc(invitationRef);
      
      // Remove the invitation from the collection's pendingInvitations
      const collectionRef = doc(firestore, 'collections', collectionId);
      await updateDoc(collectionRef, {
        pendingInvitations: arrayRemove(invitationId)
      });
  
      // Remove any related notifications
      if (invitationData && invitationData.recipientId) {
        const notificationsRef = collection(firestore, `users/${invitationData.recipientId}/notifications`);
        const q = query(notificationsRef, 
          where('type', '==', 'collection_invitation'),
          where('collectionId', '==', collectionId)
        );
        const notificationsSnapshot = await getDocs(q);
  
        notificationsSnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
      }
  
      // Refresh pending invitations
      const invitations = await getPendingInvitations(collectionId);
      setPendingInvitations(invitations);
  
      console.log('Invitation cancelled successfully');
    } catch (error) {
      console.error('Error cancelling invitation:', error);
      setError(error.message);
    }
  };

  const handleRemoveAccess = async (userId) => {
    try {
      await onRemoveAccess(userId);
      
      // Remove user from the collection's sharedWith array
      const collectionRef = doc(firestore, 'collections', collectionId);
      await updateDoc(collectionRef, {
        sharedWith: arrayRemove(userId)
      });

      // Remove collection from user's sharedCollections
      const userRef = doc(firestore, 'users', userId);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        const updatedSharedCollections = (userData.sharedCollections || []).filter(id => id !== collectionId);
        await updateDoc(userRef, { sharedCollections: updatedSharedCollections });
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="share-modal p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Share Collection</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <HiX className="w-6 h-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="mb-4">
          <p className="text-sm text-gray-600 mb-2">
            Note: You can only share with registered users of the app. Invite them to join so you can share with them.
          </p>
          <div className="flex items-center">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email to share with"
              className="flex-grow p-2 border rounded-l"
              required
            />
            <button type="submit" className="bg-orange-500 text-white p-2 rounded-r">
              Share
            </button>
          </div>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </form>

        <h3 className="font-bold mt-4 mb-2">Shared with:</h3>
        {sharedUsers.length === 0 ? (
          <p>This collection is not shared with anyone.</p>
        ) : (
          <ul>
            {sharedUsers.map(user => (
              <li key={user.id} className="flex items-center justify-between mb-2 p-2 rounded">
                <div>
                  <p className="font-semibold">{user.displayName || user.email}</p>
                  {user.displayName && <p className="text-sm text-gray-600">{user.email}</p>}
                </div>
                <button
                  onClick={() => handleRemoveAccess(user.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Modal>
  );
};

export default ShareCollectionModal;