import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { storage } from '../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import { AuthContext } from '../contexts/AuthContext';

const RecentActivity = ({ items }) => {
  const [imageUrls, setImageUrls] = useState({});
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchImageUrls = async () => {
      if (!user) return;

      const urls = {};
      for (const item of items) {
        if (item.imageUrl) {
          try {
            // Use the same approach for both shared and owned items
            const imageRef = ref(storage, item.imageUrl);
            const url = await getDownloadURL(imageRef);
            urls[item.id] = url;
          } catch (error) {
            console.error(`Error fetching image for item ${item.id}:`, error);
            // Don't set a placeholder URL, let the img tag handle the error
          }
        }
      }
      setImageUrls(urls);
    };

    if (items.length > 0 && user) {
      fetchImageUrls();
    }
  }, [items, user]);

  return (
    <div className="recent-activity">
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Recently Added</h2>
        {items.length === 0 ? (
          <p>No recent items found.</p>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {items.map(item => (
              <div key={item.id} className="border rounded-lg p-2">
                <Link to={`/item/${item.id}`} className="block">
                  {item.imageUrl ? (
                    <img 
                      src={imageUrls[item.id] || item.imageUrl}
                      alt={item.name} 
                      className="w-full h-32 object-cover mb-2 rounded" 
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/img/placeholder-image.jpg'; // Ensure this path is correct
                      }}
                    />
                  ) : (
                    <div className="w-full h-32 bg-gray-200 flex items-center justify-center mb-2 rounded">
                      No Image
                    </div>
                  )}
                  <h3 className="font-semibold text-sm truncate">{item.name}</h3>
                  {item.isShared && (
                    <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs">Shared</span>
                  )}
                  {item.createdAt && (
                    <p className="text-xs text-gray-500">
                      Added: {item.createdAt.toDate().toLocaleDateString()}
                    </p>
                  )}
                </Link>
              </div>
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default RecentActivity;