// hooks/useSearch.js
import { useState, useEffect, useCallback } from 'react';
import Fuse from 'fuse.js';

export const useSearch = () => {
  const [itemsFuse, setItemsFuse] = useState(null);
  const [collectionsFuse, setCollectionsFuse] = useState(null);

  const initializeSearch = useCallback(async (allItems, collections) => {
    console.log('Initializing Fuse with items:', allItems);
    
    setItemsFuse(new Fuse(allItems, {
      keys: ['name', 'description'],
      threshold: 0.3,
      includeScore: true,
      minMatchCharLength: 2,
    }));

    setCollectionsFuse(new Fuse(collections, {
      keys: ['name'],
      threshold: 0.3,
      includeScore: true,
      minMatchCharLength: 2,
    }));
  }, []);

  const performSearch = useCallback((searchTerm) => {
    if (!itemsFuse || !collectionsFuse) {
      console.log('Fuse instances not initialized');
      return { items: [], collections: [] };
    }

    try {
      console.log('Performing search with term:', searchTerm);
      const itemResults = itemsFuse.search(searchTerm);
      const collectionResults = collectionsFuse.search(searchTerm);

      console.log('Raw item results:', itemResults);
      console.log('Raw collection results:', collectionResults);

      const searchResults = {
        items: itemResults.map(result => result.item),
        collections: collectionResults.map(result => result.item),
      };

      console.log('Processed search results:', searchResults);
      return searchResults;
    } catch (error) {
      console.error('Error performing search:', error);
      return { items: [], collections: [] };
    }
  }, [itemsFuse, collectionsFuse]);

  const getAutocompleteSuggestions = useCallback((searchTerm) => {
    if (!itemsFuse || !collectionsFuse) return [];

    try {
      const itemResults = itemsFuse.search(searchTerm).slice(0, 3);
      const collectionResults = collectionsFuse.search(searchTerm).slice(0, 2);

      return [
        ...itemResults.map(result => ({ ...result.item, type: 'item' })),
        ...collectionResults.map(result => ({ ...result.item, type: 'collection' }))
      ];
    } catch (error) {
      console.error('Error getting autocomplete suggestions:', error);
      return [];
    }
  }, [itemsFuse, collectionsFuse]);

  return {
    initializeSearch,
    performSearch,
    getAutocompleteSuggestions,
  };
};