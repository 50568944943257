// contexts/CollectionContext.js
import React, { createContext, useContext, useMemo, useState, useCallback } from 'react';
import { AuthContext } from './AuthContext';
import { useCollections } from '../hooks/useCollections';
import { useItems } from '../hooks/useItems';
import { useSearch } from '../hooks/useSearch';
import { useSharing } from '../hooks/useSharing';
import { useNotifications } from '../hooks/useNotifications';
import { firestore, storage } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject, getMetadata } from 'firebase/storage';

export const CollectionContext = createContext();

export const CollectionProvider = ({ children }) => {
  const { user, refreshUser } = useContext(AuthContext);
  const [userItemCount, setUserItemCount] = useState(0);
  const [userCollectionCount, setUserCollectionCount] = useState(0);
  const collectionsHook = useCollections(user, setUserCollectionCount);
  const itemsHook = useItems(user, collectionsHook.sharedCollections, setUserItemCount);
  const searchHook = useSearch(user, itemsHook.getAllItems, collectionsHook.collections, collectionsHook.sharedCollections);
  const sharingHook = useSharing(user);
  const notificationsHook = useNotifications(user);

  const updateStorageUsage = useCallback(async (change) => {
    if (user) {
      const userRef = doc(firestore, `users/${user.uid}`);
      await updateDoc(userRef, { 
        storageUsage: user.storageUsage + change 
      });
      await refreshUser();
    }
  }, [user, refreshUser]);

  const addItem = useCallback(async (item, collectionIds) => {
    const itemId = await itemsHook.addItem(item, collectionIds);
    if (item.image) {
      const imageRef = ref(storage, `itemImages/${user.uid}/${itemId}`);
      await uploadBytes(imageRef, item.image);
      const metadata = await getMetadata(imageRef);
      const sizeInMB = metadata.size / (1024 * 1024);
      await updateStorageUsage(sizeInMB);
    }
    return itemId;
  }, [itemsHook, user, updateStorageUsage]);

  const editItem = useCallback(async (itemId, updatedItem, newCollectionIds) => {
    const oldItem = await itemsHook.getItemDetails(itemId);
    
    let imageUrl = oldItem.imageUrl;
    let sizeChange = 0;
  
    if (updatedItem.image instanceof File) {
      // New image file to upload
      const imageRef = ref(storage, `itemImages/${user.uid}/${itemId}`);
      await uploadBytes(imageRef, updatedItem.image);
      imageUrl = `itemImages/${user.uid}/${itemId}`; // Store the reference path, not the full URL
      
      // Calculate size change
      if (oldItem.imageUrl) {
        const oldImageRef = ref(storage, oldItem.imageUrl);
        const oldMetadata = await getMetadata(oldImageRef);
        sizeChange -= oldMetadata.size / (1024 * 1024);
        await deleteObject(oldImageRef);
      }
      const newMetadata = await getMetadata(imageRef);
      sizeChange += newMetadata.size / (1024 * 1024);
    } else if (updatedItem.imageUrl === null && oldItem.imageUrl) {
      // Image was deleted
      const imageRef = ref(storage, oldItem.imageUrl);
      const metadata = await getMetadata(imageRef);
      sizeChange -= metadata.size / (1024 * 1024);
      await deleteObject(imageRef);
      imageUrl = null;
    }
  
    // Update the item in Firestore
    await itemsHook.editItem(itemId, { ...updatedItem, imageUrl }, newCollectionIds);
    
    // Update storage usage
    if (sizeChange !== 0) {
      await updateStorageUsage(sizeChange);
    }
  }, [itemsHook, user, updateStorageUsage]);

  const deleteItem = useCallback(async (itemId) => {
    const item = await itemsHook.getItemDetails(itemId);
    await itemsHook.deleteItem(itemId);
    if (item.imageUrl) {
      const imageRef = ref(storage, `itemImages/${user.uid}/${itemId}`);
      const metadata = await getMetadata(imageRef);
      const sizeInMB = metadata.size / (1024 * 1024);
      await deleteObject(imageRef);
      await updateStorageUsage(-sizeInMB);
    }
  }, [itemsHook, user, updateStorageUsage]);

  const value = useMemo(() => ({
    ...collectionsHook,
    ...itemsHook,
    ...searchHook,
    ...sharingHook,
    ...notificationsHook,
    userItemCount,
    userCollectionCount,
    addItem,
    editItem,
    deleteItem,
  }), [collectionsHook, itemsHook, searchHook, sharingHook, notificationsHook, userItemCount, userCollectionCount, addItem, editItem, deleteItem]);

  return (
    <CollectionContext.Provider value={value}>
      {children}
    </CollectionContext.Provider>
  );
};