import React, { useContext, useEffect, useState, useCallback } from 'react';
import RecentActivity from './RecentActivity';
import { CollectionContext } from '../contexts/CollectionContext';
import { AuthContext } from '../contexts/AuthContext';
import Modal from './Modal';
import AddItem from './AddItem';
import { HiPlus } from "react-icons/hi2";
import { useNavigate } from 'react-router-dom';
import { showToast } from '../utils/toast';
import { MAX_ITEMS } from '../hooks/useItems';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Discover = () => {
  const { getAllItems, addItem } = useContext(CollectionContext);
  const { user } = useContext(AuthContext);
  const [hasItems, setHasItems] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userItemCount, setUserItemCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [recentItems, setRecentItems] = useState([]);
  const navigate = useNavigate();

  const fetchItems = useCallback(async () => {
    try {
      setLoading(true);
      const { userCreatedItems, allItems } = await getAllItems();
      setHasItems(allItems.length > 0);
      setUserItemCount(userCreatedItems.length);
      
      // Sort items by createdAt timestamp in descending order
      const sortedItems = allItems.sort((a, b) => {
        return b.createdAt?.toDate?.() - a.createdAt?.toDate?.() || 0;
      });
      
      setRecentItems(sortedItems.slice(0, 15)); // Get the 15 most recent items
      setLoading(false);
    } catch (err) {
      console.error('Error fetching items:', err);
      setError(err.message);
      setLoading(false);
    }
  }, [getAllItems]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleAddItemClick = () => {
    if (user && !user.emailVerified) {
      showToast.warning('Please verify your email before adding items.');
    } else if (userItemCount >= MAX_ITEMS) {
      showToast.warning(`You can only create a maximum of ${MAX_ITEMS} items.`);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleAddItem = async (newItem) => {
    try {
      const newItemId = await addItem(newItem, newItem.collections);
      setIsModalOpen(false);
      navigate(`/item/${newItemId}`);
      // Refresh the items list
      fetchItems();
    } catch (error) {
      console.error('Error adding item:', error);
      showToast.error('Failed to add item. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="mx-auto p-4">
        <Skeleton height={50} width={200} className="mb-4" />
        <Skeleton height={100} className="mb-4" />
        <Skeleton height={20} count={3} className="mb-2" />
        <Skeleton height={200} className="mb-4" />
        <Skeleton height={50} width={150} />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="mx-auto p-4">
      {!hasItems && (
        <div className="bg-orange-100 border-orange-500 text-orange-700 p-8 mb-4 rounded text-center">
          <h2 className="font-bold">Welcome to Lendovu!</h2>
          <p>Get started by adding your first item to your collection.</p>
          <button 
            onClick={handleAddItemClick}
            className="inline-flex items-center mt-2 bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded"
            disabled={userItemCount >= MAX_ITEMS}
          >
            <HiPlus className="mr-2" /> Add Your First Item
          </button>
        </div>
      )}
      <h1 className="text-3xl font-bold mb-4">
        Discover
      </h1>
      
      <RecentActivity items={recentItems} />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddItem 
          onAddItem={handleAddItem} 
          onClose={() => setIsModalOpen(false)} 
          userItemCount={userItemCount}
        />
      </Modal>
    </div>
  );
};

export default Discover;