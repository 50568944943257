import React, { useState, useEffect } from 'react';

const ImageUpload = ({ onImageSelected, initialImage }) => {
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(initialImage || '');

  useEffect(() => {
    if (initialImage) {
      setPreviewUrl(initialImage);
    }
  }, [initialImage]);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setImage(selectedFile);
      setPreviewUrl(URL.createObjectURL(selectedFile));
      onImageSelected(selectedFile);
      console.log('Selected file:', selectedFile);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleImageChange} accept="image/*" />
      {previewUrl && (
        <div>
          <p>Selected Image:</p>
          <img src={previewUrl} alt="Preview" style={{ maxWidth: '300px' }} />
        </div>
      )}
    </div>
  );
};

export default ImageUpload;