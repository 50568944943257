import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { firestore, collection, query, where, onSnapshot } from '../firebase';
import { CollectionContext } from '../contexts/CollectionContext';
import Modal from './Modal';
import AddItem from './AddItem';
import { showToast } from '../utils/toast';
import { HiMagnifyingGlass, HiPlus, HiBars3, HiBell } from "react-icons/hi2";
import { MAX_ITEMS } from '../hooks/useItems';

function Header({ toggleSidebar }) {
  const { user } = useContext(AuthContext);
  const { addItem, getAllItems } = useContext(CollectionContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userCreatedItemsCount, setUserCreatedItemsCount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const fetchTotalItems = async () => {
      if (user) {
        try {
          const { userCreatedItems } = await getAllItems();
          setUserCreatedItemsCount(userCreatedItems.length);
        } catch (error) {
          console.error('Error fetching items:', error);
        }
      }
    };
    fetchTotalItems();
  }, [user, getAllItems]);

  useEffect(() => {
    if (user) {
      const notificationsRef = collection(firestore, `users/${user.uid}/notifications`);
      const q = query(notificationsRef, where('read', '==', false));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const validNotifications = snapshot.docs.filter(doc => {
          const data = doc.data();
          return data.type === 'collection_invitation' && data.status !== 'cancelled';
        });
        setNotificationCount(validNotifications.length);
      });

      return () => unsubscribe();
    }
  }, [user]);
  
  const handleAddItemClick = () => {
    if (user && !user.emailVerified) {
      showToast.warning(
        <div>
          Please <Link to="/verify-email" className="text-blue-500 underline">verify your email</Link> before adding collections.
        </div>
      );
    } else if (userCreatedItemsCount >= MAX_ITEMS) {
      showToast.warning(
        <div>
          You can only create a maximum of {MAX_ITEMS} items on the free plan.{' '}
          <Link to="/upgrade" className="text-blue-500 underline">
            Upgrade to Pro
          </Link>
        </div>
      );
    } else {
      setIsModalOpen(true);
    }
  };

  const currentCollectionId = location.pathname.startsWith('/collection/') 
    ? location.pathname.split('/')[2] 
    : null;

  const handleAddItem = async (newItem) => {
    try {
      await addItem(newItem, newItem.collections);
      setIsModalOpen(false);
      navigate('/items');
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  return (
    <header className="p-2">
      <div className="mx-auto header-content">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            {user && (
              <button 
                onClick={toggleSidebar} 
                className="mr-4 p-2 text-2xl"
                aria-label="Open menu"
              >
                <HiBars3 />
              </button>
            )}
  
            <Link to={user ? "/discover" : "/"} className="text-2xl font-bold text-white hover:text-gray-200">
              <img alt='lendovu logo' className='header-logo' src='/img/Logo.png'/>
            </Link>
          </div>
  
          <div className="flex items-center">
            {user && (
                <>
                  <button 
                    onClick={handleAddItemClick}
                    className="flex items-center bg-orange-500 text-white p-2 rounded mr-2"
                  >
                    <HiPlus /> Add Item
                  </button>
                  <Link 
                    to="/search" 
                    className={`text-2xl p-2 ${location.pathname === '/search' ? 'text-orange-500' : ''}`}
                    aria-label="Go to search page"
                  >
                    <HiMagnifyingGlass />
                  </Link>
                  <Link 
                    to="/notifications" 
                    className={`relative mr-4 transition ease-in-out duration-100 ${location.pathname === '/notifications' ? 'text-orange-500 -rotate-12 ' : ''}`}
                  >
                    <HiBell className="text-2xl" />
                    {notificationCount > 0 && (
                      <span className="absolute top-0 right-0 h-2 w-2 bg-red-500 rounded-full"></span>
                    )}
                  </Link>
                </>
              )}
            {user ? (
              <Link to="/profile" className="text-gray hover:text-gray-200"></Link>
            ) : (
              <nav>
                <Link to="/pricing" className={`mr-4 hover:text-orange-500 ${location.pathname === '/pricing' ? 'text-orange-500' : ''}`}>Pricing</Link>
                <Link to="/login" className="mr-4 hover:text-orange-500">Login</Link>
                <Link to="/register" className="hover:text-orange-500">Register</Link>
              </nav>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <AddItem 
          onAddItem={handleAddItem} 
          onClose={() => setIsModalOpen(false)} 
          preSelectedCollection={currentCollectionId}
        />
      </Modal>
    </header>
  );
}

export default Header;