import React from 'react';

function PrivacyPolicy() {

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <h2 className="text-xl font-semibold mt-4 mb-2">1. Information Collection</h2>
      <p>We collect information you provide directly to us when you create an account, create libraries, add items, or share libraries. This may include your email address, display name, and information about your items and libraries.</p>

      <h2 className="text-xl font-semibold mt-4 mb-2">2. Use of Information</h2>
      <p>We use the information we collect to operate, maintain, and improve Lendovu, to communicate with you, and to protect our users.</p>
      
      <h2 className="text-xl font-semibold mt-4 mb-2">3. Information Sharing</h2>
      <p>We do not sell your personal information. We may share your information with other users when you choose to share a library. We may also share information with service providers who assist us in operating Lendovu.</p>
      
      <h2 className="text-xl font-semibold mt-4 mb-2">4. Data Security</h2>
      <p>We implement reasonable security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure.</p>
      
      <h2 className="text-xl font-semibold mt-4 mb-2">5. Data Retention</h2>
      <p>We retain your information for as long as your account is active or as needed to provide you services. We may retain certain information even after you delete your account if retention is reasonably necessary to comply with our legal obligations, meet regulatory requirements, or prevent fraud and abuse.</p>
      
      <h2 className="text-xl font-semibold mt-4 mb-2">6. Your Rights</h2>
      <p>You can access, update, or delete your information by logging into your account. If you wish to delete your account, please contact us.</p>
      
      <h2 className="text-xl font-semibold mt-4 mb-2">7. Changes to this Policy</h2>
      <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>
      
      <h2 className="text-xl font-semibold mt-4 mb-2">8. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at <a href='mailto:support@lendovu.com'>support@lendovu.com</a>.</p>

    </div>
  );
}

export default PrivacyPolicy;