// VerifyEmail.js
import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { sendEmailVerification, reload } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function VerifyEmail() {
  const [message, setMessage] = useState("A verification email has been sent to your email address. Please check your inbox and click the verification link.");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkEmailVerification = async () => {
      const user = auth.currentUser;
      if (user) {
        await reload(user);
        if (user.emailVerified) {
          navigate('/profile');
        }
      } else {
        navigate('/login');
      }
    };

    const unsubscribe = auth.onAuthStateChanged(checkEmailVerification);

    return () => unsubscribe();
  }, [navigate]);

  const handleResendVerification = async () => {
    setMessage(null);
    setError(null);
    try {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
        setMessage('Verification email resent. Please check your inbox.');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Verify Your Email</h2>
      {message && <p className="mb-4">{message}</p>}
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <button 
        onClick={handleResendVerification}
        className="w-full p-2 bg-orange-500 text-white rounded"
      >
        Resend Verification Email
      </button>
    </div>
  );
}

export default VerifyEmail;