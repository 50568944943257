import React from 'react';
import { Link } from 'react-router-dom';
import { HiLockClosed, HiEmojiSad } from "react-icons/hi";

const UnauthorizedPage = () => {
  const funnyMessages = [
    "Oops! This item is playing hide and seek, and you're not 'it'!",
    "Sorry, this collection is on vacation and left you off the postcard list.",
    "Access denied: This content is hibernating for unauthorized users.",
    "Uh-oh! You've stumbled into the VIP section without a golden ticket.",
    "This page is like a secret treehouse, and you forgot the password!",
  ];

  const randomMessage = funnyMessages[Math.floor(Math.random() * funnyMessages.length)];

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 p-8">
      <div className="text-center">
        <HiLockClosed className="mx-auto text-6xl text-orange-500 mb-4" />
        <h1 className="text-4xl font-bold mb-4">Whoops! Access Denied</h1>
        <p className="text-xl mb-8">{randomMessage}</p>
        <div className="flex items-center justify-center text-lg mb-8">
          <HiEmojiSad className="text-2xl mr-2" />
          <span>Error 403: Forbidden</span>
        </div>
        <Link
          to="/discover"
          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Go Back to Safety
        </Link>
      </div>
    </div>
  );
};

export default UnauthorizedPage;