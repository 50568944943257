// components/UpgradePage.js
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { getPremiumStatus } from '../utils/getPremiumStatus';
import { getPortalUrl, getCheckoutUrl } from '../utils/stripePayment';
import { firebaseApp } from '../firebase';

const MONTHLY_PRICE_ID = 'price_1PgsQqC2MiACY3xSuWxWSZNy';
const YEARLY_PRICE_ID = 'price_1PkqWrC2MiACY3xSMAVV4fqb';

function UpgradePage() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isPremium, setIsPremium] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkPremiumStatus = async () => {
      if (user) {
        try {
          const premium = await getPremiumStatus(user.uid);
          setIsPremium(premium);
        } catch (err) {
          console.error('Error checking premium status:', err);
        } finally {
          setLoading(false);
        }
      } else {
        navigate('/login');
      }
    };
    checkPremiumStatus();
  }, [user, navigate]);

  const handleManageSubscription = async () => {
    try {
      setError(null);
      const portalUrl = await getPortalUrl(firebaseApp);
      window.location.href = portalUrl;
    } catch (error) {
      console.error('Error getting portal URL:', error);
      setError('Unable to access the customer portal at this time. Please try again later or contact support.');
    }
  };

  const handleUpgrade = async (priceId) => {
    try {
      setError(null);
      const checkoutUrl = await getCheckoutUrl(firebaseApp, priceId);
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Error getting checkout URL:', error);
      setError('Unable to process the upgrade at this time. Please try again later or contact support.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-md mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Manage Your Subscription</h2>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
          <p>{error}</p>
        </div>
      )}
      {isPremium ? (
        <>
          <p className="mb-4">You currently have a Pro subscription. Thank you for your support!</p>
          <button
            onClick={handleManageSubscription}
            className="w-full p-2 bg-orange-500 text-white rounded mb-2"
          >
            Manage Subscription
          </button>
          <p className="text-sm text-gray-600">You can cancel or change your plan here.</p>
        </>
      ) : (
        <>
          <p className="mb-4">Upgrade to Pro for unlimited collections, items, and more!</p>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <button
              onClick={() => handleUpgrade(MONTHLY_PRICE_ID)}
              className="p-2 bg-orange-500 text-white rounded"
            >
              Upgrade Monthly ($1.99/month)
            </button>
            <button
              onClick={() => handleUpgrade(YEARLY_PRICE_ID)}
              className="p-2 bg-green-500 text-white rounded"
            >
              Upgrade Yearly ($19.99/year)
            </button>
          </div>
          <p className="text-sm text-gray-600">Choose the plan that works best for you. You can cancel anytime.</p>
        </>
      )}
    </div>
  );
}

export default UpgradePage;